import {Route, Routes} from 'react-router-dom'
import {AuthLayout} from './AuthLayout'
import {PhoneForm} from './components/PhoneForm'
import {OtpVerify} from "./components/OtpVerify";
import {Login} from "./components/Login";

const AuthPage = () => (
    <Routes>
        <Route element={<AuthLayout/>}>
            <Route path=':type' element={<PhoneForm/>}/>
            <Route path=':type' element={<PhoneForm/>}/>
            <Route path=':type' element={<PhoneForm/>}/>
            <Route path='otp/verify' element={<OtpVerify/>}/>
            <Route path='login' element={<Login/>}/>
            <Route index element={<PhoneForm/>}/>
        </Route>
    </Routes>
)

export {AuthPage}
