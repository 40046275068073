import {useQuery} from 'react-query'
import {PlanEditModalForm} from './PlanEditModalForm'
import {useListView} from "../PlanListViewProvider";
import {isNotEmpty, QUERIES} from "../../../../../_metronic/helpers";
import {getPlanById, getUserById} from "../../../../requests/_admin_requests";

const PlanEditModalFormWrapper = () => {
    const {itemIdForUpdate, setItemIdForUpdate} = useListView()
    const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
    const {
        isLoading,
        data: user,
        error,
    } = useQuery(
        `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
        () => {
            return getPlanById(itemIdForUpdate)
        },
        {
            cacheTime: 0,
            enabled: enabledQuery,
            onError: (err) => {
                setItemIdForUpdate(undefined)
                console.error(err)
            },
        }
    )

    if (!itemIdForUpdate) {
        return <PlanEditModalForm isPlanLoading={isLoading} plan={{id: undefined}}/>
    }

    if (!isLoading && !error && user) {
        return <PlanEditModalForm isPlanLoading={isLoading} plan={user}/>
    }

    return null
}

export {PlanEditModalFormWrapper}
