// @ts-nocheck
import {Column} from 'react-table'
import {Setting} from "../../../_models";
import {UserCustomHeader} from "../../apps/user-management/users-list/table/columns/UserCustomHeader";
import {ColumnHeader} from "../columns/ColumnHeader";
import {TextCell} from "../columns/TextCell";
import {BadgeCell} from "../columns/BadgeCell";
import {EditActionCell} from "../columns/EditActionCell";
import {useListView} from "./SettingListViewProvider";

const SettingColumns: ReadonlyArray<Column<Setting>> = [
    {
        Header: (props) => <ColumnHeader tableProps={props} title='ID' className='min-w-125px' />,
        id: 'key',
        Cell: ({...props}) => <BadgeCell text={props.data[props.row.index]?.key} />,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='نام' className='min-w-125px' />,
        id: 'name',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.label} bold={true} />,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='مقدار' className='min-w-125px' />,
        id: 'value',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.value} bold={true} />,
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='عملیات' className='text-end min-w-100px' />
        ),
        id: 'actions',
        Cell: ({...props}) => <EditActionCell id={props.data[props.row.index].key} provider={useListView()} />,
    },
]

export {SettingColumns}
