import React from "react";

export const AlertMessage = (message: string | React.ReactChild | React.ReactFragment | null | undefined, type: string | undefined) => {
    if (!message) return null;

    return (
        <div
            className={`fv-plugins-message-container ml-0 card text-${type} bg-${type}-subtle border-dashed border-${type} p-2`}>
            <span role={type}>{message}</span>
        </div>
    );
};
