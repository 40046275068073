/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {ID, KTIcon} from "../../../../_metronic/helpers";
import {MenuComponent} from "../../../../_metronic/assets/ts/components";
import {useQueryResponseSubs} from "./AdminSubQueryResponseProvider";
import {useListView} from "./AdminSubListViewProvider";
import {getSubById, reactivateSub} from "../../../requests/_admin_requests";
import clsx from "clsx";
import {AlertMessage} from "../../registeration/components/AlertMessage";
import invoiceItem from "../../auth/components/partials/InvoiceItem";
import {Invoice} from "../../../_models";
import {formatNumber} from "../../../utils/hellpers";

type Props = {
    id: ID,
    status: any
}

const AdminSubActionCell: FC<Props> = ({id, status}) => {
    const {setItemIdForUpdate, setSecondModal, setThirdModal, setMessage} = useListView()
    const {refetch} = useQueryResponseSubs()
    const [showInvoiceModal, setShowInvoiceModal] = useState(false)
    const [loadInvoiceLoading, setLoadInvoiceLoading] = useState(false)
    const [accountInvoice, setAccountInvoice] = useState<Invoice|null>(null)

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const openEditModal = () => {
        setItemIdForUpdate(id)
    }

    const openExtendModal = () => {
        if (setSecondModal) {
            setSecondModal(id)
        }
    }

    const openSuspendModal = () => {
        if (setThirdModal) {
            setThirdModal(id)
        }
    }

    const openShowInvoiceModal = () => {
        setLoadInvoiceLoading(true)
        getSubById(id).then(res => {
            if (!res || !res?.invoice) return;
            setAccountInvoice(res?.invoice[0])
            setShowInvoiceModal(true)
        }).catch(err => {

        }).finally(() => setLoadInvoiceLoading(false))
    }

    const closeInvoiceModal = () => {
        setShowInvoiceModal(false)
    }

    const reactivate = () => {
        reactivateSub(id).then(res => {
            if (setMessage) {
                setMessage({
                    type: 'success',
                    text: res?.data?.data?.message
                })
                refetch()
                setTimeout(() => setMessage(undefined), 7000)
            }
        })
    }

    return (
        <>
            <a
                href='#'
                className='btn btn-light btn-active-light-primary btn-sm'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                عملیات
                <KTIcon iconName='down' className='fs-5 m-0'/>
            </a>
            {/* begin::Menu */}
            <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                data-kt-menu='true'
            >
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                    <a className='menu-link px-3' onClick={openExtendModal}>
                        تمدید
                    </a>
                </div>
                {/* end::Menu item */}

                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                    <a
                        className='menu-link px-3'
                        data-kt-plans-table-filter='edit_row'
                        onClick={openEditModal}
                    >
                        ویرایش
                    </a>
                </div>

                <div className='menu-item px-3'>
                    <a
                        className='menu-link px-3'
                        data-kt-plans-table-filter='edit_row'
                        onClick={openShowInvoiceModal}
                    >
                        مشاهده فاکتور
                    </a>
                </div>
                {/* end::Menu item */}

                {/* begin::Menu item */}
                {
                    status === 2
                    &&
                    <div className='menu-item px-3'>
                        <a className='menu-link px-3 text-danger' onClick={openSuspendModal}>
                            مسدود کردن
                        </a>
                    </div>
                }
                {
                    status === 4
                    &&
                    <div className='menu-item px-3'>
                        <a className='menu-link px-3 text-success' onClick={reactivate}>
                            رفع مسدودیت
                        </a>
                    </div>
                }

                {/* end::Menu item */}

            </div>
            {/* end::Menu */}


            {showInvoiceModal && (
                <>
                    <div
                        className='modal fade show d-block'
                        id='kt_modal_add_user'
                        role='dialog'
                        tabIndex={-1}
                        aria-modal='true'
                    >
                        <div className='modal-dialog modal-dialog-centered mw-650px'>
                            <div className='modal-content'>
                                <div className='modal-header'>

                                    <h2 className='fw-bolder'>فاکتور کاربر</h2>
                                    <div
                                        className='btn btn-icon btn-sm btn-active-icon-primary'
                                        data-kt-users-modal-action='close'
                                        onClick={closeInvoiceModal}
                                        style={{cursor: 'pointer'}}
                                    >
                                        <KTIcon iconName='cross' className='fs-1'/>
                                    </div>
                                </div>
                                <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                                    <div className={'container'}>
                                        <div className={'row gap-8'}>
                                            <div className={'col-12'}>
                                                <div style={{display: "flex",gap: 12}}>
                                                    <span className={'fs-5 text-gray-800'}>طرف پرداخت:</span>
                                                    <span className={'fs-5 text-gray-700'}>{accountInvoice?.payment_type == 'user' ? 'مشتری' : 'سیستم'}</span>
                                                </div>
                                            </div>
                                            <div className={'col-12'}>
                                                <div style={{display: "flex",gap: 12}}>
                                                    <span className={'fs-5 text-gray-800'}>مبلغ کل:</span>
                                                    <span className={'fs-5 text-gray-700'}>{accountInvoice?.total_amount ? formatNumber(accountInvoice?.total_amount) : '-'}</span>
                                                </div>
                                            </div>
                                            <div className={'col-12'}>
                                                <div style={{display: "flex",gap: 12}}>
                                                    <span className={'fs-5 text-gray-800'}>قابل پرداخت:</span>
                                                    <span className={'fs-5 text-gray-700'}>{accountInvoice?.payable ? formatNumber(accountInvoice?.payable) : '-'}</span>
                                                </div>
                                            </div>
                                            <div className={'col-12'}>
                                                <div style={{display: "flex",gap: 12}}>
                                                    <span className={'fs-5 text-gray-800'}>تخفیف:</span>
                                                    <span className={'fs-5 text-gray-700'}>{accountInvoice?.discount ? formatNumber(accountInvoice?.discount) : '-'}</span>
                                                </div>
                                            </div>
                                            <div className={'col-12'}>
                                                <div style={{display: "flex",gap: 12}}>
                                                    <span className={'fs-5 text-gray-800'}>مالیات:</span>
                                                    <span className={'fs-5 text-gray-700'}>{accountInvoice?.tax ? formatNumber(accountInvoice?.tax) : '-'}</span>
                                                </div>
                                            </div>
                                            <div className={'col-12'}>
                                                <div style={{display: "flex",gap: 12}}>
                                                    <span className={'fs-5 text-gray-800'}>درگاه پرداختی:</span>
                                                    <span className={'fs-5 text-gray-700'}>{accountInvoice?.gateway ?? '-'}</span>
                                                </div>
                                            </div>
                                            <div className={'col-12'}>
                                                <div style={{display: "flex",gap: 12}}>
                                                    <span className={'fs-5 text-gray-800'}>تاریخ پرداخت:</span>
                                                    <span className={'fs-5 text-gray-700'}>{accountInvoice?.payment_at ?? '-'}</span>
                                                </div>
                                            </div>
                                            <div className={'col-12'}>
                                                <div style={{display: "flex",gap: 12}}>
                                                    <span className={'fs-5 text-gray-800'}>شماره فاکتور:</span>
                                                    <span className={'fs-5 text-gray-700'}>{accountInvoice?.trans_id ?? '-'}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'modal-footer'}>
                                    <button
                                        className='btn btn-light me-3'
                                        onClick={closeInvoiceModal}
                                    >
                                        بستن
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-backdrop fade show'></div>
                </>
            )}

        </>
    )
}

export {AdminSubActionCell}
