import React, {useMemo} from 'react'
import {Row, useTable} from 'react-table'
import {CustomHeaderColumn} from '../columns/CustomHeaderColumn'
import {CustomRow} from '../columns/CustomRow'
import {Coupon} from '../../../_models'
import {ListLoading} from '../ListLoading'
import {KTCard, KTCardBody} from "../../../../_metronic/helpers";
import {QueryRequestProvider} from "../core/QueryRequestProvider";
import {
    CouponsQueryResponseProvider,
    useQueryResponseDataCoupons,
    useQueryResponseLoading
} from "./CouponsQueryResponseProvider";
import {CouponListViewProvider, useListView} from "./CouponListViewProvider";
import {CouponColumns} from "./_coupons_table_columns";
import {CouponListPagination} from "./CouponListPagination";
import {CouponEditModal} from "./modals/CouponEditModal";

const CouponsTableInner = () => {
    const responseData = useQueryResponseDataCoupons()
    const isLoading = useQueryResponseLoading()
    const data = useMemo(() => responseData, [responseData])
    const columns = useMemo(() => CouponColumns, [])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })
    const {setItemIdForUpdate, itemIdForUpdate} = useListView()

    return (
        <>
            <div className="d-flex gap-3">
                <button className="btn btn-secondary" onClick={() => setItemIdForUpdate(null)}>ساخت کد تخفیف</button>
            </div>

            <KTCard className='my-5 bg-gray-100'>
                <div className="p-10 pb-0">
                    <h2 className="text-primary">مشاهده کد های تخفیف</h2>
                </div>
                <KTCardBody className='py-4'>
                    <div className='table-responsive'>
                        <table
                            id='invoice-table'
                            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                            {...getTableProps()}
                        >
                            <thead>
                            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                {headers.map((column) => (
                                    <CustomHeaderColumn key={column.id} column={column}/>
                                ))}
                            </tr>
                            </thead>
                            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                            {rows.length > 0 ? (
                                rows.map((row: Row<Coupon>, i) => {
                                    prepareRow(row)
                                    return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
                                })
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div
                                            className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            کد تخفیف در سیستم ثبت نشده است.
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                    <CouponListPagination/>
                    {isLoading && <ListLoading/>}
                    {itemIdForUpdate !== undefined && <CouponEditModal/>}
                </KTCardBody>
            </KTCard>
        </>
    )
}

const CouponsTable = () => {
    return <QueryRequestProvider>
        <CouponsQueryResponseProvider>
            <CouponListViewProvider>
                <CouponsTableInner/>
            </CouponListViewProvider>
        </CouponsQueryResponseProvider>
    </QueryRequestProvider>
}
export {CouponsTable}
