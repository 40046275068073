import {FC, useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from "../SettingListViewProvider";
import {useQueryResponseSettings} from "../SettingQueryResponseProvider";
import {Setting} from "../../../../_models";
import {adminPutSetting} from "../../../../requests/_admin_requests";
import {ListLoading} from "../../ListLoading";


type Props = {
    isSettingLoading: boolean
    setting: any
}

const SettingEditModalForm: FC<Props> = ({setting, isSettingLoading}) => {
    const {setItemIdForUpdate} = useListView()
    const {refetch} = useQueryResponseSettings()

    const [settingForEdit] = useState<Setting>({
        ...setting,
        key: setting.key,
        label: setting.label,
        value: setting.value
    })

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch()
        }
        setItemIdForUpdate(undefined)
    }

    const formik = useFormik({
        initialValues: settingForEdit,
        onSubmit: async (values, {setSubmitting}) => {
            setSubmitting(true)
            try {
                await adminPutSetting(values)
            } catch (ex) {
                console.error(ex)
            } finally {
                setSubmitting(true)
                cancel(true)
            }
        },
    })

    return (
        <>
            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                >
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='fw-bold fs-6 mb-2'>{setting.label}</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...formik.getFieldProps('value')}
                            type='text'
                            name='value'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched?.value && formik.errors?.value},
                                {
                                    'is-valid': formik.touched?.value && !formik.errors?.value,
                                }
                            )}
                            autoComplete='off'
                            disabled={formik.isSubmitting || isSettingLoading}
                        />
                        {formik.touched.value && formik.errors.value && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.value}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={isSettingLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
                    >
                        <span className='indicator-label'>ذخیره</span>
                        {(formik.isSubmitting || isSettingLoading) && (
                            <span className='indicator-progress'> منتظر باشید...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}
            </form>
            {(formik.isSubmitting || isSettingLoading) && <ListLoading/>}
        </>
    )
}

export {SettingEditModalForm}
