/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {useQueryClient} from 'react-query'
import {useQueryResponseSub} from "../userSubs/SubsQueryResponseProvider";
import {MenuComponent} from "../../../../_metronic/assets/ts/components";
import {ID} from "../../../../_metronic/helpers";
import {useListView} from "./RadiusListViewProvider";

const RadiusActionCell: FC<Props> = ({id}) => {
    const {
        setItemIdForUpdate,
        setSecondModal,
        setThirdModal,
        setForthModal
    } = useListView()
    const {query} = useQueryResponseSub()
    const queryClient = useQueryClient()

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const openSyncModal = () => {
        if (setSecondModal) {
            setSecondModal(id)
        }
    }

    const openRebuildModal = () => {
        if (setForthModal) {
            setForthModal(id)
        }
    }

    const openCleanModal = () => {
        if (setThirdModal) {
            setThirdModal(id)
        }
    }

    const openEditModal = () => {
        setItemIdForUpdate(id)
    }


    return <div className="d-flex flex-column gap-2">
        <button className='btn btn-secondary btn-small-ct px-3' onClick={openSyncModal}>
            Sync
            <i className="fa-solid fa-check-double"></i>
        </button>
        <button className='btn btn-dark btn-small-ct px-3' onClick={openRebuildModal}>
            Rebuild
            <i className="fa-solid fa-file-arrow-up"></i>
        </button>
        <button className='btn btn-danger btn-small-ct px-3' onClick={openCleanModal}>
            Clean
            <i className="fa-solid fa-remove"></i>
        </button>

        <button className='btn btn-primary btn-small-ct px-3' onClick={openEditModal}>
            ویرایش
            <i className="fa-solid fa-file-edit"></i>
        </button>
    </div>
}

type Props = {
    id: ID
}

export {RadiusActionCell}
