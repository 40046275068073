import {useQuery} from 'react-query'
import {isNotEmpty, QUERIES} from "../../../../../../_metronic/helpers";
import {getSub} from "../../../../../requests/_requests";
import {useListView} from "../../SubListViewProvider";
import {SubEditModalForm} from "./SubEditModalForm";

const SubEditModalFormWrapper = () => {
    const {itemIdForUpdate, setItemIdForUpdate} = useListView()
    const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
    const {
        isLoading,
        data: sub,
        error,
    } = useQuery(
        `${QUERIES.SUBS_LIST}-sub-${itemIdForUpdate}`,
        () => {
            return getSub(itemIdForUpdate)
        },
        {
            cacheTime: 0,
            enabled: enabledQuery,
            onError: (err) => {
                setItemIdForUpdate(undefined)
            },
        }
    )

    if (!itemIdForUpdate) {
        return <SubEditModalForm isLoading={isLoading} sub={{id: undefined, days: 0}}/>
    }

    if (!isLoading && !error && sub) {
        return <SubEditModalForm isLoading={isLoading} sub={sub}/>
    }

    return null
}

export {SubEditModalFormWrapper}
