/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from "../../../../_metronic/assets/ts/components";
import {useListView} from "../settings/SettingListViewProvider";

const EditActionCell: FC<Props> = ({id, provider}) => {
    const {setItemIdForUpdate} = useListView()

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const openEditModal = () => {
        setItemIdForUpdate(id)
    }


    return (
        <button className='btn btn-secondary btn-small-ct px-3' onClick={openEditModal}>
            ویرایش
            <i className="fa-solid fa-file-edit"></i>
        </button>
    )
}

type Props = {
    id: any,
    provider: {
        setItemIdForUpdate: Function
    }
}

export {EditActionCell}
