import React from 'react';
import {Link} from 'react-router-dom';
import {useIntl} from 'react-intl';

const RegisterPrompt = () => {
    const {formatMessage: text} = useIntl();

    return (
        <div className='text-gray-500 fw-semibold fs-6'>
            {"حساب کاربری ندارید؟" + ' '}
            <Link to='/auth/register' className='link-primary'>
                خرید اشتراک و ساخت حساب کاربری
            </Link>
        </div>
    );
};

export default RegisterPrompt;
