import React, {useEffect, useState} from 'react'
import {useFormik} from "formik";
import * as Yup from "yup";
import {KTIcon} from "../../../../_metronic/helpers";
import {subscriptionExtension} from "../../../requests/_admin_requests";
import {AlertMessage} from "../../registeration/components/AlertMessage";
import {useListView} from "./AdminSubListViewProvider";
import clsx from "clsx";
import {useQueryResponseSubs} from "./AdminSubQueryResponseProvider";

const initialValues = {
    days: 15
}

const validationSchema = Yup.object().shape({
    days: Yup.number()
        .min(1, 'حداقل ۱ روز باید انتخاب شود.')
        .required('وارد کردن تعداد روزها ضروری است.'),
})

const ExtensionModal = (isLoading) => {
    const {setSecondModal, secondModal} = useListView()
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [successMessage, setSuccessMessage] = useState('')
    // @ts-ignore
    const {refetch} = useQueryResponseSubs()

    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch()
        }
        if (setSecondModal) {
            setSecondModal(undefined)
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, {setSubmitting, setErrors}) => {
            setSubmitting(true)
            await subscriptionExtension(secondModal, values.days)
                .then(res => {
                    if (res.status == 200) {
                        setSuccessMessage(res?.data?.data?.message)
                        setTimeout(() => {
                            if (setSecondModal) {
                                setSecondModal(undefined)
                            }
                        }, 5000)
                    }
                })
                .catch(e => {
                    setErrors(e.response?.data?.errors)
                })
                .finally(() => setIsSubmitting(false))
        },
    })

    // @ts-ignore
    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-650px'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>تمدید اشتراک کاربر</h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => {
                                    if (setSecondModal) {
                                        setSecondModal(undefined)
                                    }
                                }}
                                style={{cursor: 'pointer'}}
                            >
                                <KTIcon iconName='cross' className='fs-1'/>
                            </div>
                            {/* end::Close */}
                        </div>
                        {/* begin::Modal body */}
                        <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                            <form className='form' onSubmit={formik.handleSubmit} noValidate>
                                {/* begin::Input group */}
                                <div className='fv-row mb-7'>
                                    {/* begin::Label */}
                                    <label className='required fw-bold fs-6 mb-2'>تعداد روز های تمدید</label>
                                    {/* end::Label */}

                                    {/* begin::Input */}
                                    <input
                                        {...formik.getFieldProps('days')}
                                        type='text'
                                        name='days'
                                        className={clsx(
                                            'form-control form-control-solid mb-3 mb-lg-0',
                                            {'is-invalid': formik.touched.days && formik.errors?.days},
                                            {
                                                'is-valid': formik.touched.days && !formik.errors?.days,
                                            }
                                        )}
                                        autoComplete='off'
                                        disabled={isSubmitting}
                                    />
                                    {formik.touched?.days && formik.errors?.days && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors?.days}</span>
                                            </div>
                                        </div>
                                    )}
                                    {/* end::Input */}
                                </div>
                                {/* end::Input group */}

                                {AlertMessage(successMessage, "success")}
                                <div className='text-center pt-15'>
                                    <button
                                        type='reset'
                                        onClick={() => cancel()}
                                        className='btn btn-light me-3'
                                        data-kt-users-modal-action='cancel'
                                        disabled={formik.isSubmitting}
                                    >
                                        لغو
                                    </button>

                                    <button
                                        type='submit'
                                        className='btn btn-primary'
                                        data-kt-users-modal-action='submit'
                                        disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                                    >
                                        <span className='indicator-label'>تمدید</span>
                                        {(formik.isSubmitting) && (
                                            <span className='indicator-progress'>لطفا منتظر بمانید...{' '}<span
                                                className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>

                            </form>
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export {ExtensionModal}
