import {useQuery} from 'react-query'
import {UserSubModalForm} from './UserSubModalForm'
import {useListView} from "../UserListViewProvider";
import {isNotEmpty, QUERIES} from "../../../../../_metronic/helpers";
import {getPlans, getUserById} from "../../../../requests/_admin_requests";
import {useEffect, useState} from "react";
import {Plan} from "../../../../_models";

const UserSubModalFormWrapper = () => {
    const {setSecondModal, secondModal} = useListView()

    const enabledQuery: boolean = isNotEmpty(secondModal)
    const [plans, setPlans] = useState<Array<Plan>>()

    const {
        isLoading,
        data: user,
        error,
    } = useQuery(
        `${QUERIES.USERS_LIST}-user-${secondModal}`,
        () => {
            return getUserById(secondModal)
        },
        {
            cacheTime: 0,
            enabled: enabledQuery,
            onError: (err) => {
                if (setSecondModal) {
                    setSecondModal(undefined)
                }
                console.error(err)
            },
        }
    )

    useEffect(() => {
        getPlans()
            .then(res => {
                setPlans(res?.data?.data)
            })
    }, []);

    if (!secondModal) {
        return <UserSubModalForm isLoading={isLoading} user={{id: undefined, phone: ""}} plans={undefined}/>
    }

    if (!isLoading && !error && user && plans) {
        return <UserSubModalForm isLoading={isLoading} user={user} plans={plans}/>
    }

    return null
}

export {UserSubModalFormWrapper}
