import {FC, useEffect} from 'react'
import {useListView} from "../SubListViewProvider";
import {SubSelect} from "../../../registeration/components/SubSelect";
import {Subscription} from "../../../../_models";

type Props = {
    sub: Subscription
}
const RenewSubModalForm: FC<Props> = ({sub}) => {
    const {setSecondModal} = useListView()

    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    return (
        <SubSelect isRenew accountId={sub.id}/>
    )
}

export {RenewSubModalForm}
