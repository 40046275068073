/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {
    createResponseContext,
    initialQueryResponse,
    initialQueryState,
    PaginationState,
    QUERIES,
    stringifyRequestQuery,
    WithChildren
} from "../../../../_metronic/helpers";
import {Coupon} from "../../../_models";
import {adminGetCoupons} from "../../../requests/_admin_requests";
import {useQueryRequest} from "../core/QueryRequestProvider";

const QueryResponseContextCoupons = createResponseContext<Coupon>(initialQueryResponse)
const CouponsQueryResponseProvider: FC<WithChildren> = ({children}) => {
    const {state} = useQueryRequest()
    const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
    const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

    useEffect(() => {
        if (query !== updatedQuery) {
            setQuery(updatedQuery)
        }
    }, [updatedQuery])

    const {
        isFetching,
        refetch,
        data: response,
    } = useQuery(
        `${QUERIES.SUBS_LIST}-${query}`,
        () => {
            return adminGetCoupons(query)
        },
        {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
    )

    return (
        <QueryResponseContextCoupons.Provider value={{isLoading: isFetching, refetch, response, query}}>
            {children}
        </QueryResponseContextCoupons.Provider>
    )
}

const useQueryResponseCoupons = () => useContext(QueryResponseContextCoupons)

const useQueryResponseDataCoupons = () => {
    const {response} = useQueryResponseCoupons()

    if (!response) {
        return []
    }

    return response?.data || []
}

const useQueryResponsePagination = () => {
    const defaultPaginationState: PaginationState = {
        links: [],
        ...initialQueryState,
    }

    const {response} = useQueryResponseCoupons()
    if (!response || !response?.meta) {
        return defaultPaginationState
    }

    return response.meta
}

const useQueryResponseLoading = (): boolean => {
    const {isLoading} = useQueryResponseCoupons()
    return isLoading
}

export {
    CouponsQueryResponseProvider,
    useQueryResponsePagination,
    useQueryResponseLoading,
    useQueryResponseDataCoupons,
    useQueryResponseCoupons
}
