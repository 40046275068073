/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from "react-intl";
import AdminDashboard from "./AdminDashboard";

const tr = {
    homepage: {
        id: 'HOMEPAGE'
    },
    dashboard: {
        id: 'DASHBOARD'
    },
}
const AdminDashboardWrapper: FC = () => {
    const {formatMessage: text} = useIntl()

    const breadcrumbs = [
        {
            title: "پنل ادمین",
            path: "/",
            isActive: true
        },
        {
            title: text(tr.dashboard),
            path: "/dashboard",
            isActive: true
        }
    ]
    return (
        <EnableSidebar>
            <PageTitle breadcrumbs={breadcrumbs}>
                {text(tr.dashboard)}
            </PageTitle>
            <AdminDashboard/>
        </EnableSidebar>
    )
}

export {AdminDashboardWrapper}
