import {Route, Routes} from 'react-router-dom'
import {AuthLayout} from "../auth/AuthLayout";
import {Subscription} from "./components/Subscription";
import {AccountDetail} from "./components/AccountDetail";
import {PrePay} from "./components/PrePay";
import {PostPayment} from "./components/PostPyament";

const RegistrationPage = () => (
    <Routes>
        <Route element={<AuthLayout/>}>
            <Route path='subscriptions' element={<Subscription/>}/>
            <Route path='account' element={<AccountDetail/>}/>
            <Route path='prepay' element={<PrePay/>}/>
            <Route path='postpayment' element={<PostPayment/>}/>
        </Route>
    </Routes>
)

export {RegistrationPage}
