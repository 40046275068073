import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {DisableSidebar} from '../../_metronic/layout/core'
import {WithChildren} from '../../_metronic/helpers'
import {AuthLayout} from "../modules/auth/AuthLayout";
import {useAuth} from "../modules/auth";
import {AdminLogin} from "../modules/auth/components/AdminLogin";
import {AdminDashboardWrapper} from "../admin/dashboard/AdminDashboardWrapper";
import {AdminLayout} from "../../_metronic/layout/AdminLayout";
import {RadiusPage} from "../admin/radius/RadiusPage";
import {AdminSubsPage} from "../admin/subscriptions/AdminSubsPage";
import {CouponsPage} from "../admin/coupons/CouponsPage";
import {SettingPage} from "../admin/settings/SettingPage";
import {Error404} from "../modules/errors/components/Error404";
import {ErrorsLayout} from "../modules/errors/ErrorsLayout";
import {PlansPage} from "../admin/plans/PlansPage";
import {UsersPage} from "../admin/users/UsersPage";

const AdminRoutes = () => {
    const {adminUser} = useAuth()

    const ProfilePage = lazy(() => import('../pages/profile/ProfilePage'))
    const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))

    return (
        <Routes>

            {adminUser ? (
                <Route element={<AdminLayout/>}>

                    {/* Pages */}
                    <Route path='dashboard' element={<AdminDashboardWrapper/>}/>
                    <Route path='users' element={<UsersPage/>}/>
                    <Route path='subscriptions' element={<AdminSubsPage/>}/>
                    <Route path='coupons' element={<CouponsPage/>}/>
                    <Route path='settings' element={<SettingPage/>}/>
                    <Route path='plans' element={<PlansPage/>}/>
                    <Route path='radius' element={<RadiusPage/>}/>

                    <Route
                        path='profile/*'
                        element={
                            <SuspensedView>
                                <ProfilePage/>
                            </SuspensedView>
                        }
                    />

                    {/*redirect /admin to /admin/dashboard*/}
                    <Route path='' element={<Navigate to='/admin/dashboard'/>}/>

                </Route>
            ) : (
                <>
                    <Route element={<AuthLayout/>}>
                        <Route path='login' element={<AdminLogin/>}/>
                    </Route>
                    <Route path='*' element={<Navigate to='/admin/login'/>}/>
                </>
            )}

            {/* Page Not Found */}
            <Route element={<ErrorsLayout/>}>
                <Route path='*' element={<Error404/>}/>
            </Route>

        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return (
        <Suspense fallback={<TopBarProgress/>}>
            <DisableSidebar>{children}</DisableSidebar>
        </Suspense>
    )
}

export {AdminRoutes}
