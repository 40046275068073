import React, {FC, useEffect} from 'react'
import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {KTCard} from "../../../_metronic/helpers";
import {InvoiceTable} from "../../modules/tables/Invoice/InvoiceTable";
import {getClientSettings, getServersList} from "../../requests/_requests";

const InvoiceListPage: FC = () => {

    const breadcrumbs = [
        {
            title: 'پنل کاربری',
            path: "/",
            isActive: true
        },
        {
            title: 'تاریخچه پرداخت',
            path: "/transactions",
            isActive: true
        }
    ]

    useEffect(() => {
        document.title = 'تاریخچه پرداخت'
    }, []);
    return (
        <EnableSidebar>
            <PageTitle breadcrumbs={breadcrumbs}>
                تاریخچه پرداخت شما
            </PageTitle>
            <div className="w-75">
                در این صفحه تمامی پرداخت هایی که تابحال در سیستم کاور تریدر انجام داده اید، قابل نمایش است.
            </div>
            <KTCard className='my-5 bg-gray-100'>
                <div className="p-10 pb-0">
                    <h2 className="text-primary">تاریخچه پرداخت کاربر</h2>
                </div>
                <InvoiceTable/>
            </KTCard>
        </EnableSidebar>
    )
}

export {InvoiceListPage}
