import clsx from "clsx";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {getOpenInvoice, updateProfile, updateSubscriptionAuth} from "../../../requests/_requests";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import AuthSubtitle from "../../auth/components/partials/AuthSubtitle";
import ErrorAlert from "../../auth/components/partials/ErrorAlert";
import {useAuth} from "../../auth";
import * as Yup from "yup";

const tr = {
    next: {
        id: 'NEXT'
    },
    previous: {
        id: 'PREVIOUS'
    },
    title: {
        id: 'ACCOUNT.TITLE'
    },
    name: {
        id: "AUTH.INPUT.FULLNAME"
    },
    desc1: {
        id: "ACCOUNT.DESC.1"
    },
    desc2: {
        id: "ACCOUNT.DESC.2"
    },
    systemSelect: {
        id: "ACCOUNT.SYSTEM_SELECT"
    },
    iSelect: {
        id: "ACCOUNT.I_SELECT"
    },
    username: {
        id: "ACCOUNT.USERNAME"
    },
    password: {
        id: "ACCOUNT.PASSWORD"
    },
};

export function AccountDetail() {
    const {formatMessage: text} = useIntl()
    const navigate = useNavigate();
    const [autoGenerate, setAutoGenerate] = useState(true);
    const [subId, setSubId] = useState<number>();
    const {currentUser} = useAuth()

    const validationSchema = () => {
        if (autoGenerate) {
            if (!currentUser?.name) {
                return Yup.object().shape({
                    name: Yup.string().required('نام خود را وارد کنید.')
                })
            }
        } else {
            return Yup.object().shape({
                name: Yup.string()
                    .required('نام خود را وارد کنید.'),
                username: Yup.string()
                    .min(4, 'نام کاربری باید حداقل ۴ کاراکتر باشد.')
                    .max(50, 'نام کاربری می‌تواند حداکثر ۵۰ کاراکتر باشد.')
                    .required('نام کاربری را وارد کنید.'),
                password: Yup.string()
                    .min(4, 'رمز عبور باید حداقل ۴ کاراکتر باشد.')
                    .max(50, 'رمز عبور می‌تواند حداکثر ۵۰ کاراکتر باشد.')
                    .required('رمز عبور را وارد کنید.'),
            })
        }
    }

    const formik = useFormik({
        initialValues: {
            autoGenerate: true,
            name: '',
            username: '',
            password: ''
        },
        validationSchema: validationSchema(),
        onSubmit: async (values, {setFieldError, setSubmitting, setErrors, validateField}) => {
            try {
                if (values.name) {
                    const {data} = await updateProfile(values.name)
                }
                if (!autoGenerate) {
                    const {data: subData} = await updateSubscriptionAuth(subId, values.username, values.password)
                }
                navigate('/registration/prepay')
            } catch (error: any) {
                setErrors(error.response.data.errors)
            } finally {
                setSubmitting(false)
            }
        },
    })

    const handleToggleGenerate = () => {
        if (autoGenerate) {
            setAutoGenerate(false)
        } else {
            setAutoGenerate(true)
        }
    }

    useEffect(() => {
        getOpenInvoice().then(res => {
            setSubId(res.data.data.accounts[0].id)
        }).catch(() => {
            navigate('/registration/subscriptions')
        })
    }, [])

    useEffect(() => {
        formik.setErrors({})
    }, [autoGenerate])

    return (
        <form
            dir='rtl'
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
        >
            {/* begin::Heading */}
            <h1 className='text-dark fw-bolder'>
                {text(tr.title)}
            </h1>
            <AuthSubtitle/>
            {/* end::Heading */}

            <div className='card card-custom card-flush my-5'>
                <div className="card-body">
                    {!currentUser?.name &&
                        <div className='my-5'>
                            <label className='form-label fw-bolder text-dark'>
                                {text(tr.name)}
                            </label>
                            <input
                                {...formik.getFieldProps('name')}
                                className={clsx(
                                    'form-control bg-transparent',
                                    {'is-invalid': formik.touched.name && formik.errors.name},
                                    {
                                        'is-valid': formik.touched.name && !formik.errors.name,
                                    }
                                )}
                                type='text'
                                name='name'
                            />
                            <ErrorAlert touched={formik.touched.name} message={formik.errors.name}/>
                        </div>
                    }

                    <div className="my-5">
                        <p>{text(tr.desc1)}</p>
                        <p>{text(tr.desc2)}</p>
                    </div>


                    {/*begin::Radio group*/}
                    <div className="my-5 d-flex justify-content-evenly">
                        {/*begin::Radio button*/}
                        <label
                            onClick={() => setAutoGenerate(true)}
                            className={clsx(
                                "btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-6 mb-5 ", {
                                    "active": autoGenerate,
                                })}
                        >
                            {/*end::Description*/}
                            <div className="d-flex align-items-center me-2">
                                {/*begin::Radio*/}
                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                    <input className="form-check-input" type="radio" name="plan" value="startup"
                                           checked={autoGenerate}/>
                                </div>
                                {/*end::Radio*/}

                                {/*begin::Info*/}
                                <div className="flex-grow-1">
                                    <div className="fw-semibold opacity-50">
                                        {text(tr.systemSelect)}
                                    </div>
                                </div>
                                {/*end::Info*/}
                            </div>
                            {/*end::Description*/}

                            {/*end::Price*/}
                        </label>
                        {/*end::Radio button*/}
                        {/*begin::Radio button*/}
                        <label
                            onClick={() => setAutoGenerate(false)}
                            className={clsx(
                                "btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-6 mb-5 ", {
                                    "active": !autoGenerate,
                                })}
                        >
                            {/*end::Description*/}
                            <div className="d-flex align-items-center me-2">
                                {/*begin::Radio*/}
                                <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                    <input className="form-check-input" type="radio" name="plan" value="startup"
                                           checked={!autoGenerate}/>
                                </div>
                                {/*end::Radio*/}

                                {/*begin::Info*/}
                                <div className="flex-grow-1">
                                    <div className="fw-semibold opacity-50">
                                        {text(tr.iSelect)}

                                    </div>
                                </div>
                                {/*end::Info*/}
                            </div>
                            {/*end::Description*/}

                            {/*end::Price*/}
                        </label>
                        {/*end::Radio button*/}

                    </div>
                    {/*end::Radio group*/}


                    {
                        !autoGenerate
                        &&
                        <div className="d-flex flex-row gap-5">
                            <div className="w-50">
                                <label className={clsx('form-label', {'text-secondary': autoGenerate})}>
                                    {text(tr.username)}
                                </label>
                                <input
                                    disabled={autoGenerate}
                                    {...formik.getFieldProps('username')}
                                    className={clsx(
                                        'form-control bg-transparent',
                                        {'is-invalid': formik.touched.username && formik.errors.username},
                                        {
                                            'is-valid': formik.touched.username && !formik.errors.username,
                                        }
                                    )}
                                    type='text'
                                    name='username'
                                    autoComplete='off'
                                />
                                <ErrorAlert touched={formik.touched.username} message={formik.errors.username}/>
                            </div>
                            <div className="w-50">
                                <label className={clsx('form-label', {'text-secondary': autoGenerate})}>
                                    {text(tr.password)}
                                </label>
                                <input
                                    disabled={autoGenerate}
                                    {...formik.getFieldProps('password')}
                                    className={clsx(
                                        'form-control bg-transparent',
                                        {'is-invalid': formik.touched.password && formik.errors.password},
                                        {
                                            'is-valid': formik.touched.password && !formik.errors.password,
                                        }
                                    )}
                                    type='password'
                                    name='password'
                                    autoComplete='off'
                                />
                                <ErrorAlert touched={formik.touched.password} message={formik.errors.password}/>
                            </div>

                        </div>
                    }

                </div>
            </div>

            {/* begin::Action */}
            <div className='d-flex justify-content-between mb-10'>
                <button onClick={() => navigate(-1)}
                        className='btn btn-outline btn-outline-primary'>
                        <span className='indicator-label'>
                            {text(tr.previous)}
                        </span>
                </button>

                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!formik.isSubmitting &&
                        <span className='indicator-label'>
                            {text(tr.next)}
                        </span>}
                    {formik.isSubmitting && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                            {text(tr.next)}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            {/* end::Action */}

        </form>
    );
}
