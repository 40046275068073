import {useMemo} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from '../columns/CustomHeaderColumn'
import {CustomRow} from '../columns/CustomRow'
import {subscriptionsColumns} from './_table_columns'
import {Subscription} from '../../../_models'
import {ListLoading} from '../ListLoading'
import {KTCardBody} from "../../../../_metronic/helpers";
import {
    SubsQueryResponseProvider,
    useQueryResponseDataSub,
    useQueryResponseLoading
} from "./SubsQueryResponseProvider";
import {QueryRequestProvider} from "../../apps/user-management/users-list/core/QueryRequestProvider";
import {ListViewProvider, useListView} from "./SubListViewProvider";
import {RenewSubModal} from "./subs-modal/RenewSubModal";

const SubscriptionInner = () => {
    const subscriptions = useQueryResponseDataSub()
    const isLoading = useQueryResponseLoading()
    const data = useMemo(() => subscriptions, [subscriptions])
    const columns = useMemo(() => subscriptionsColumns, [])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })
    const {secondModal} = useListView()

    return (
        <>
            <KTCardBody className='py-4'>
                <div className='table-responsive'>
                    <table
                        id='subscriptions-table'
                        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                        {...getTableProps()}
                    >
                        <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            {headers.map((column: ColumnInstance<Subscription>) => (
                                <CustomHeaderColumn key={column.id} column={column}/>
                            ))}
                        </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                        {rows.length > 0 ? (
                            rows.map((row: Row<Subscription>, i) => {
                                prepareRow(row)
                                return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
                            })
                        ) : (
                            <tr>
                                <td colSpan={7}>
                                    <div
                                        className='d-flex text-center w-100 align-content-center justify-content-center'>
                                        در حال حاظر اشتراکی ندارید
                                    </div>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
                {isLoading && <ListLoading/>}
            </KTCardBody>
            {secondModal && <RenewSubModal/>}
        </>
    )
}

const SubscriptionTable = () => {
    return <QueryRequestProvider>
        <SubsQueryResponseProvider>
            <ListViewProvider>
                <SubscriptionInner/>
            </ListViewProvider>
        </SubsQueryResponseProvider>
    </QueryRequestProvider>
}
export {SubscriptionTable}
