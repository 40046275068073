/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useQueryClient} from 'react-query'
import {useQueryResponseSub} from "../userSubs/SubsQueryResponseProvider";
import {MenuComponent} from "../../../../_metronic/assets/ts/components";
import {ID} from "../../../../_metronic/helpers";
import {useListView} from "../userSubs/SubListViewProvider";
import {payInvoice} from "../../../requests/_requests";
import clsx from "clsx";

const MultiActionCell: FC<Props> = ({id, status, invoice_id}) => {
    const {setItemIdForUpdate, setSecondModal, setShow} = useListView()
    const [loading, setLoading] = useState(false)
    const {query} = useQueryResponseSub()
    const queryClient = useQueryClient()
    const [alert, setAlert] = useState(false)
    const [message, setMessage] = useState("")

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const openEditModal = () => {
        setItemIdForUpdate(id)
    }

    const openRenewModal = () => {
        if (setSecondModal) {
            setSecondModal(id)
        }
    }

    const openShowModal = () => {
        if (setShow) {
            setShow(id)
        }
    }

    const paymentRequest = () => {
        if (invoice_id) {
            setAlert(false)
            setLoading(true)
            setLoading(true)
            payInvoice(invoice_id)
                .then(res => {
                    if (res?.data?.data?.redirect_url) {
                        window.location.assign(res.data.data.redirect_url);
                    }
                })
                .catch((res) => {
                    setAlert(true)
                    setMessage(res.response?.data?.error?.message)
                    setLoading(false)
                })
        }
    }

    return (
       <>
           {alert &&
               <div className="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10" style={{position: 'fixed', left: 20, bottom: 0}}>
                   {/*<span className="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">...</span>*/}
                   <div className="d-flex flex-column text-primary pe-0 pe-sm-10">
                       <h5 className="mb-1">خطا در اتصال به درگاه</h5>
                       <span>{message ?? "خطا در اتصال به درگاه"}</span>
                   </div>

                   <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                       <span className="svg-icon svg-icon-1 svg-icon-primary">X</span>
                   </button>
               </div>

           }
           <div className="d-flex gap-3 justify-content-evenly gap-md-3">
               <button className='btn btn-secondary btn-sm px-3' onClick={openShowModal}>
                   مشاهده فاکتور
                   <i className="fa-solid fa-file-invoice"></i>
               </button>
               <button className='btn btn-secondary btn-sm px-3' onClick={openEditModal}>
                   تغییر رمز اتصال
                   <i className="fa-solid fa-key"></i>
               </button>
               {
                   status === 1
                       ?
                       <button className='btn btn-primary btn-sm px-3' onClick={paymentRequest}>
                    <span
                        className={clsx({
                            'indicator-progress': loading,
                            'indicator-label': !loading
                        })}
                        style={{display: 'block'}}>
                           پرداخت
                        {loading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
                    </span>
                       </button>
                       :
                       <button className='btn btn-primary btn-sm px-3' onClick={openRenewModal}>
                           تمدید اشتراک
                           <i className="fa-solid fa-arrow-rotate-right"></i>
                       </button>
               }

           </div>
       </>
    )
}

type Props = {
    id: ID,
    status: number,
    invoice_id: string
}

export {MultiActionCell}
