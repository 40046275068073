import {useQuery} from 'react-query'
import {useListView} from "../SubListViewProvider";
import {isNotEmpty, QUERIES} from "../../../../../_metronic/helpers";
import {getSub} from "../../../../requests/_requests";
import {RenewSubModalForm} from "./RenewSubModalForm";

const RenewSubModalFormWrapper = () => {
    const {secondModal, setSecondModal} = useListView()
    const enabledQuery: boolean = isNotEmpty(secondModal)
    const {
        isLoading,
        data: sub,
        error,
    } = useQuery(
        `${QUERIES.SUBS_LIST}-sub-${secondModal}`,
        () => {
            return getSub(secondModal)
        },
        {
            cacheTime: 0,
            enabled: enabledQuery,
            onError: (err) => {
                if (setSecondModal) {
                    setSecondModal(0)
                }
            },
        }
    )

    if (!secondModal) {
        return <RenewSubModalForm sub={{id: undefined, days: 0}}/>
    }

    if (!isLoading && !error && sub) {
        return <RenewSubModalForm sub={sub}/>
    }

    return null
}

export {RenewSubModalFormWrapper}
