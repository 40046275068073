import React, {useEffect} from 'react'
import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {KTCard} from "../../../_metronic/helpers";
import {SettingTable} from "../../modules/tables/settings/SettingTable";
import {SettingEditModal} from "../../modules/tables/settings/setting-edit-modal/SettingEditModal";

const SettingPage = () => {
    const breadcrumbs = [
        {
            title: 'پنل ادمین',
            path: "/admin/dashboard",
            isActive: true
        },
        {
            title: 'تنظیمات',
            path: "/admin/settings",
            isActive: true
        }
    ]
    useEffect(() => {
        document.title = 'تنظیمات'
    }, []);
    return (
        <EnableSidebar>
            <PageTitle breadcrumbs={breadcrumbs}>
                تنظیمات
            </PageTitle>

            <KTCard className='my-5 bg-gray-100'>
                <div className="p-10 pb-0">
                    <h2 className="text-primary">تنظیمات کاورترید</h2>
                </div>
                <SettingTable/>
            </KTCard>
        </EnableSidebar>
    )
}

export {SettingPage}
