import React, {FC, useEffect} from 'react'
import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {KTCard, KTCardBody, toAbsoluteUrl} from "../../../_metronic/helpers";

const SupportPage: FC = () => {

    const breadcrumbs = [
        {
            title: 'پنل کاربری',
            path: "/",
            isActive: true
        },
        {
            title: 'پشتیبانی آنلاین',
            path: "/support",
            isActive: true
        }
    ]
    useEffect(() => {
        document.title = 'پشتیبانی آنلاین'
    }, []);
    return (
        <EnableSidebar>
            <PageTitle breadcrumbs={breadcrumbs}>
               پشتیبانی آنلاین کاور تریدر
            </PageTitle>
            <div className="w-75">
                در این صفحه می‌توانید روش های ارتباط با پشتیبانی کاور تریدر را بررسی کنید و با توجه به نیاز خود با پشتیبانان ما در تماس باشید. کارشناسان ما هر روز هفته از ساعت ۸ صبح الی ۲۴ آماده پاسخگویی به مشکلات، سوالات و پیشنهادات و انتقادات شما کاربر گرامی هستند.
            </div>
            <KTCard className='my-5 bg-gray-100'>
                <div className="p-10 pb-0">
                    <h2 className="text-primary">برای ارتباط با کارشناسان کاور تریدر، روی یکی از دکمه های زیر کلیک کنید</h2>
                </div>
                <KTCardBody className='py-4'>
                    <div className='d-flex'>
                        <div className='w-75 w-100 m-5 gap-5 d-flex'>
                            <a target="_blank" href="https://t.me/CoverTraderAdmin" className="btn btn-primary btn-sm">ارتباط با پشتیبانی تلگرام</a>
                            <a target="_blank" href="https://www.goftino.com/c/cLPger" className="btn btn-primary btn-sm">ارتباط با پشتیبانی آنلاین وبسایت</a>
                            <a target="_blank" href="tel:+982191035512" className="btn btn-primary btn-sm">تماس مستقیم با پشتیبانی</a>
                        </div>
                        <div className='w-md-25'>
                            <img
                                className='position-md-absolute d-none d-md-flex'
                                style={{bottom: 0}}
                                src={toAbsoluteUrl('/media/support-illustration.svg')}
                                alt='پشتیبانی'
                            />
                        </div>
                    </div>
                </KTCardBody>
            </KTCard>
        </EnableSidebar>
    )
}

export {SupportPage}
