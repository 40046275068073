// @ts-nocheck
import {Column} from 'react-table'
import {ColumnHeader} from '../columns/ColumnHeader'
import {Subscription} from "../../../_models";
import {TextCell} from "../columns/TextCell";
import {SubActionCell} from "../columns/SubActionCell";
import {SubStatusCell} from "../columns/SubStatusCell";

const subscriptionsColumns: ReadonlyArray<Column<Subscription>> = [
    {
        Header: (props) => <ColumnHeader tableProps={props} title='وضعیت' className='min-w-125px'/>,
        id: 'status',
        Cell: ({...props}) => <SubStatusCell status={props.data[props.row.index]?.status}/>,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='نوع اشتراک' className='min-w-125px'/>,
        id: 'plan_name',
        Cell: ({...props}) => <TextCell text={props.data[props.row.index].plan_name} bold={true}/>,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='نام کاربری' className='min-w-125px'/>,
        id: 'username',
        Cell: ({...props}) => <TextCell text={props.data[props.row.index].username}/>,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='تاریخ انقضا' className='min-w-125px'/>,
        id: 'expiry',
        Cell: ({...props}) => <TextCell text={props.data[props.row.index].expire_at}/>,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='تمدید اشتراک' className='text-end min-w-125px'/>,
        id: 'actions',
        Cell: ({...props}) => <SubActionCell
            id={props.data[props.row.index].id}
            status={props.data[props.row.index]?.status}
            invoice={props.data[props.row.index]?.invoice?.[0]}
        />,
    },

]

export {subscriptionsColumns}
