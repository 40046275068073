import {FC, useEffect} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {DrawerComponent} from '../../assets/ts/components'
import {WithChildren} from '../../helpers'
import {usePageData} from "../core";
import {Breadcrumb, BreadcrumbItem} from "react-bootstrap";

const Content: FC<WithChildren> = ({children}) => {
    const location = useLocation()
    const {pageTitle, pageBreadcrumbs, pageDescription} = usePageData()

    useEffect(() => {
        DrawerComponent.hideAll()
    }, [location])

    return (
        <div id='kt_content_container' className={clsx('content flex-row-fluid')}>
            <h3>{pageTitle}</h3>
            <Breadcrumb className="fs-7 mb-5">
                {pageBreadcrumbs?.map((i, key) =>
                    <BreadcrumbItem
                        key={key}
                        className='link-dark'
                        href={i.path}
                        active={i.isActive}>
                        {i.title}
                    </BreadcrumbItem>)}
            </Breadcrumb>
            <p>{pageDescription}</p>
            {children}
        </div>
    )
}

export {Content}
