import {useQuery} from 'react-query'
import {AdminSubEditModalForm} from './AdminSubEditModalForm'
import {isNotEmpty, QUERIES} from "../../../../../_metronic/helpers";
import {getSubById} from "../../../../requests/_admin_requests";
import {useListView} from "../AdminSubListViewProvider";

const AdminSubEditModalFormWrapper = () => {
    const {itemIdForUpdate, setItemIdForUpdate} = useListView()
    const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
    const {
        isLoading,
        data,
        error,
    } = useQuery(
        `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
        () => {
            return getSubById(itemIdForUpdate)
        },
        {
            cacheTime: 0,
            enabled: enabledQuery,
            onError: (err) => {
                setItemIdForUpdate(undefined)
            },
        }
    )

    if (!itemIdForUpdate) {
        return <AdminSubEditModalForm isLoading={isLoading} sub={{id: undefined, days: 0}}/>
    }

    if (!isLoading && !error && data) {
        return <AdminSubEditModalForm isLoading={isLoading} sub={data}/>
    }

    return null
}

export {AdminSubEditModalFormWrapper}
