/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useQueryClient} from 'react-query'
import {useQueryResponseSub} from "../userSubs/SubsQueryResponseProvider";
import {MenuComponent} from "../../../../_metronic/assets/ts/components";
import {ID} from "../../../../_metronic/helpers";
import {useListView} from "../userSubs/SubListViewProvider";
import clsx from "clsx";
import {payInvoice} from "../../../requests/_requests";

const SubActionCell: FC<Props> = ({id, status, invoice}) => {
    const {setSecondModal} = useListView()
    const {query} = useQueryResponseSub()
    const queryClient = useQueryClient()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const openRenewModal = () => {
        if (setSecondModal) {
            setSecondModal(id)
        }
    }

    const paymentRequest = () => {
        if (invoice.id) {
            setLoading(true)
            payInvoice(invoice?.id.toString())
                .then(res => {
                    if (res?.data?.data?.redirect_url) {
                        window.location.assign(res.data.data.redirect_url);
                    }
                })
                .catch(() => {
                    setLoading(false)
                })
        }
    }

    return (
        <>
            {
                status === 1
                    ?
                    <button className='btn btn-primary btn-small-ct px-3' onClick={paymentRequest}>
                    <span
                        className={clsx({
                            'indicator-progress': loading,
                            'indicator-label': !loading
                        })}
                        style={{display: 'block'}}>
                           پرداخت
                        {loading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
                    </span>
                    </button>
                    :
                    <button className='btn btn-primary btn-small-ct px-3' onClick={openRenewModal}>
                        تمدید اشتراک
                        <i className="fa-solid fa-arrow-rotate-right"></i>
                    </button>
            }

        </>
    )
}

type Props = {
    id: ID,
    status: number,
    invoice: { id: ID }
}

export {SubActionCell}
