// @ts-nocheck
import {Column} from 'react-table'
import {Subscription} from "../../../_models";
import {UserCustomHeader} from "../../apps/user-management/users-list/table/columns/UserCustomHeader";
import {ColumnHeader} from "../columns/ColumnHeader";
import {TextCell} from "../columns/TextCell";
import {BadgeCell} from "../columns/BadgeCell";
import {AdminSubActionCell} from "./AdminSubActionCell";
import {StatusCell} from "../columns/StatusCell";
import {SubStatusCell} from "../columns/SubStatusCell";

const AdminSubColumns: ReadonlyArray<Column<Subscription>> = [
    {
        Header: (props) => <ColumnHeader tableProps={props} title='ID' className='min-w-30px'/>,
        id: 'id',
        Cell: ({...props}) => <BadgeCell text={props.data[props.row.index]?.id}/>,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='نام' className='min-w-100px'/>,
        id: 'user.name',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.user?.name} bold={true}/>,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='شماره تلفن' className='min-w-100px'/>,
        id: 'user.phone',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.user?.phone} bold={true}/>,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='نام اشتراک' className='min-w-100px'/>,
        id: 'plan_name',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.plan_name} bold={true}/>,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='وضعیت' className='min-w-100px'/>,
        id: 'status',
        Cell: ({...props}) => <SubStatusCell status={props?.data[props.row.index]?.status} bold={true}/>,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='نام کاربری' className='min-w-100px'/>,
        id: 'username',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.username} bold={true}/>,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='تاریخ انقضا' className='min-w-100px'/>,
        id: 'expire_at',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.expire_at} bold={true}/>,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='تاریخ ساخت' className='min-w-100px'/>,
        id: 'created_at',
        Cell: ({...props}) => <TextCell text={props.data[props.row.index]?.created_at}/>,
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='عملیات' className='text-end min-w-50px'/>
        ),
        id: 'actions',
        Cell: ({...props}) => <AdminSubActionCell id={props.data[props.row.index]?.id} status={props.data[props.row.index]?.status}/>,
    },
]

export {AdminSubColumns}
