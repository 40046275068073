import {Dispatch, SetStateAction} from 'react'

export type ID = undefined | null | number

export type PaginationState = {
    page: number
    current_page: number
    last_page?: number
    per_page?: number
    from?: number
    to?: number
    total?: number
    path?: number
    items_per_page: 10 | 30 | 50 | 100
    links?: Array<{ label: string; active: boolean; url: string | null; page: number | null }>
}

export type SortState = {
    sort?: string
    order?: 'asc' | 'desc'
}

export type FilterState = {
    filter?: unknown
}

export type SearchState = {
    search?: string
}

export type Response<T> = {
    data?: T
    meta?: PaginationState
    payload?: {
        message?: string
        errors?: {
            [key: string]: Array<string>
        }
        pagination?: PaginationState
    }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
    state: QueryState
    updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
    page: 1,
    current_page: 1,
    items_per_page: 10,
}

export const initialQueryRequest: QueryRequestContextProps = {
    state: initialQueryState,
    updateState: () => {
    },
}

export type QueryResponseContextProps<T> = {
    response?: Response<Array<T>> | undefined
    refetch: () => void
    isLoading: boolean
    query: string
}

export const initialQueryResponse = {
    refetch: () => {
    }, isLoading: false, query: ''
}

export type ListViewContextProps = {
    selected: Array<ID>
    onSelect: (selectedId: ID) => void
    onSelectAll: () => void
    clearSelected: () => void
    // NULL => (CREATION MODE) | MODAL IS OPENED
    // NUMBER => (EDIT MODE) | MODAL IS OPENED
    // UNDEFINED => MODAL IS CLOSED
    itemIdForUpdate?: ID
    setItemIdForUpdate: Dispatch<SetStateAction<ID>>
    isAllSelected: boolean
    disabled: boolean
    secondModal?: any
    setSecondModal?: Dispatch<SetStateAction<any>>
    show?: ID
    setShow?: Dispatch<SetStateAction<any>>
    message?: any
    setMessage?: Dispatch<SetStateAction<any>>
    thirdModal?: ID
    setThirdModal?: Dispatch<SetStateAction<ID>>
    forthModal?: ID
    setForthModal?: Dispatch<SetStateAction<ID>>
}

export const initialListView: ListViewContextProps = {
    selected: [],
    onSelect: () => {
    },
    onSelectAll: () => {
    },
    clearSelected: () => {
    },
    setItemIdForUpdate: () => {
    },
    isAllSelected: false,
    disabled: false,
    secondModal: false,
    setSecondModal: () => {
    },
    setShow: () => {
    },
    message: undefined,
    setMessage: () => {
    },
}

type initialStateType = {
    state: boolean,
    setState: Dispatch<SetStateAction<any>>
}
export const initialState: initialStateType = {
    setState: () => {
    },
    state: false,
}
