// @ts-nocheck
import {Column} from 'react-table'
import {Subscription} from "../../../_models";
import {TextCell} from "./TextCell";
import {MultiActionCell} from "./MultiActionCell";
import {SubStatusCell} from "./SubStatusCell";

const subscriptionsListColumns: ReadonlyArray<Column<Subscription>> = [
    {
        id: 'status',
        Cell: ({...props}) => <SubStatusCell status={props.data[props.row.index]?.status}/>,
    },
    {
        type: 'right-100',
        id: 'plan_name',
        Cell: ({...props}) => <TextCell
            prefix="نوع اشتراک: "
            text={props.data[props.row.index].plan_name}
            prefixSmDisplayNone={true}
            bold={true}/>,
    },
    {
        type: 'right-100',
        id: 'username',
        Cell: ({...props}) => <TextCell
            prefix="نام کاربری: "
            text={props.data[props.row.index].username}
            copyBtn={true}
        />,
    },
    {
        type: 'right-100',
        id: 'password',
        Cell: ({...props}) => <TextCell
            prefix="رمز عبور: "
            text={props.data[props.row.index].password}
            copyBtn={true}
        />,
    },
    {
        type: 'right-100',
        id: 'start',
        Cell: ({...props}) => <TextCell prefix="تاریخ فعال سازی: " text={props.data[props.row.index]?.status === 2 ? props.data[props.row.index].starts_at : "-"}/>,
    },
    {
        type: 'top-left',
        id: 'expiry',
        Cell: ({...props}) => <TextCell prefix="تاریخ انقضا: " text={props.data[props.row.index]?.status === 2 ? props.data[props.row.index]?.expire_at : '-'}/>,
    },
    {
        type: 'second-top-left',
        id: 'remaining_days',
        Cell: ({...props}) => <TextCell appendix={props.data[props.row.index]?.status === 2 ? " روز مانده تا پایان اشتراک" : ""}
                                        text={props.data[props.row.index]?.status === 2 ? props.data[props.row.index]?.remaining_days.toString() : ''}/>,
    },
    {
        id: 'actions',
        Cell: ({...props}) => <MultiActionCell id={props.data[props.row.index].id} status={props.data[props.row.index]?.status} invoice_id={props.data[props.row.index]?.invoice?.[0]?.id.toString()}/>,
    },

]

export {subscriptionsListColumns}
