import {FC} from 'react'

type Props = {
  is_active?: boolean
}

const StatusCell: FC<Props> = ({is_active}) => (
    <>
        {is_active
            ? <div className='badge badge-light-success fw-bolder p-3'>فعال</div>
            : <div className='badge badge-light-danger fw-bolder p-3'>غیر فعال</div>}
    </>
)

export {StatusCell}
