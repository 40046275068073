import {useIntl} from "react-intl";
import {useLocation, useNavigate} from "react-router-dom";
import AuthSubtitle from "../../auth/components/partials/AuthSubtitle";
import React, {useEffect, useState} from "react";
import {getInvoice, payInvoice} from "../../../requests/_requests";
import {InvoiceModel, useAuth} from "../../auth";
import InvoiceItem from "../../auth/components/partials/InvoiceItem";
import {toAbsoluteUrl} from "../../../../_metronic/helpers";

const tr = {
    title: {
        id: 'PAY.RESULT'
    },
    success: {
        id: 'PAY.SUCCESS'
    },
    desc: {
        id: 'PAY.SUCCESS.DESC'
    },
    unsuccessful: {
        id: 'PAY.UNSUCCESSFUL'
    },
    unsuccessfulDesc: {
        id: 'PAY.UNSUCCESSFUL.DESC'
    },
    phone: {
        id: 'PHONE'
    },
    selectedSub: {
        id: 'PREPAY.SELECT_SUBSCRIPTION'
    },
    username: {
        id: 'ACCOUNT.USERNAME'
    },
    password: {
        id: 'ACCOUNT.PASSWORD'
    },
    price: {
        id: 'PREPAY.PRICE'
    },
    paid: {
        id: 'PAID'
    },
    enterDashboard: {
        id: 'ENTER_USER_DASHBOARD'
    },
    instructions: {
        id: 'INSTRUCTIONS'
    },
}

export function PostPayment() {
    const {formatMessage: text} = useIntl()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [invoice, setInvoice] = useState<InvoiceModel>()
    const [successful, setSuccessful] = useState(false)
    const {currentUser} = useAuth()

    const location = useLocation();

    const getQueryParams = () => {
        return new URLSearchParams(location.search)
    };

    const params = getQueryParams()
    const invoiceId = params.get('invoice_id')
    const paymentSuccess = params.get('payment_successful')
    const redirectTo404 = () => navigate('/error/404')

    useEffect(() => {
        if (invoiceId) {
            getInvoice(params.get('invoice_id'))
                .then((res) => {
                    setInvoice(res.data.data)
                }).catch(() => redirectTo404())
        }
        if (paymentSuccess) {
            const truthyValues = ['true', '1', 'yes']
            const boolValue = truthyValues.includes(paymentSuccess.toLowerCase())
            setSuccessful(boolValue)
        } else {
            setSuccessful(false)
        }
    }, [])

    const paymentRequest = () => {
        if (invoice) {
            setLoading(true)
            payInvoice(invoice.id.toString())
                .then(res => {
                    if (res?.data?.data?.redirect_url) {
                        window.location.assign(res.data.data.redirect_url);
                    }
                })
                .catch(() => {
                    setLoading(false)
                })
        }
    }


    return (
        <div className='w-100'>
            {/* begin::Heading */}
            <h1 className='text-dark fw-bolder'>
                {text(tr.title)}
            </h1>
            <AuthSubtitle/>
            {/* end::Heading */}

            <div className='card card-custom card-flush my-5'>
                <div className="card-body">

                    {/* begin::Form group */}

                    <div className="d-flex flex-column justify-content-center align-items-center">
                        {successful ?
                            <>
                                <img alt='payment successful' src={toAbsoluteUrl('/media/payment-successful.svg')}
                                     className='h-140px'/>
                                <h3 className="fw-bold text-primary my-10">
                                    {text(tr.success)}
                                </h3>
                                <p>
                                    {text(tr.desc)}
                                </p>
                            </>
                            :
                            <>
                                <img alt='payment Unsuccessful' src={toAbsoluteUrl('/media/payment-unsuccessful.svg')}
                                     className='h-140px'/>
                                <h3 className="fw-bold text-primary my-10">
                                    {text(tr.unsuccessful)}
                                </h3>
                                <p>
                                    {text(tr.unsuccessfulDesc)}
                                </p>
                            </>
                        }


                    </div>
                    {/* end::Form group */}
                    {invoice &&
                        <>
                            {successful ?
                                <>
                                    <div className="d-flex flex-row justify-content-between align-items-center my-10">
                                        <h6>{text(tr.selectedSub)}</h6>
                                        <div
                                            className="card bg-primary text-white p-2">{invoice.accounts[0].plan.name}</div>
                                    </div>
                                    <InvoiceItem name={text(tr.username)} value={invoice.accounts[0].username}/>
                                    <InvoiceItem name={text(tr.password)} value={invoice.accounts[0].password}/>
                                    <InvoiceItem name={text(tr.paid)} value={invoice.payable}/>
                                </>
                                :
                                <>
                                    <div className="d-flex flex-row justify-content-between align-items-center my-10">
                                        <h6>{text(tr.selectedSub)}</h6>
                                        <div
                                            className="card bg-primary text-white p-2">{invoice.accounts[0].plan.name}</div>
                                    </div>
                                    <InvoiceItem name={'شماره تلفن'} value={currentUser?.phone}/>
                                    <InvoiceItem name={'کد پرداخت'} value={invoice.pay_code}/>
                                    <InvoiceItem name={'مبلغ'} value={invoice.payable}/>
                                </>
                            }
                        </>
                    }
                </div>


            </div>

            {successful ?
                <div className='d-flex justify-content-between mb-10'>
                    <button onClick={() => navigate('/dashboard')} className='btn btn-outline btn-outline-primary'>
                        <span className='indicator-label'>
                            {text(tr.enterDashboard)}
                        </span>
                    </button>

                    <button
                        className='btn btn-primary'
                        onClick={() => navigate('/instructions')}
                    >
                    <span className='indicator-label'>
                            {text(tr.instructions)}
                    </span>
                    </button>
                </div>
                :
                <div className='d-flex justify-content-between mb-10'>
                    <button onClick={() => navigate('/support')} className='btn btn-outline btn-outline-primary'>
                        <span className='indicator-label'>
                            ارتباط با پشتیبانی کاورترید
                        </span>
                    </button>

                    <button
                        className='btn btn-primary'
                        onClick={paymentRequest}
                    >
                        {!loading &&
                            <span className='indicator-label'>پرداخت مجدد</span>
                        }
                        {loading && (
                            <span className='indicator-label'>پرداخت مجدد
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            }

        </div>
    );
}