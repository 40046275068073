import {FC, useState} from 'react'
import clsx from "clsx";
import {KTSVG} from "../../../../_metronic/helpers";

type Props = {
    text?: string,
    prefix?: string,
    appendix?: string,
    bold?: boolean,
    copyBtn?: boolean
    prefixSmDisplayNone?: boolean
}

const TextCell: FC<Props> = ({text, bold, prefix, copyBtn, appendix, prefixSmDisplayNone}) => {
    const [copySuccess, setCopySuccess] = useState(false)
    const handleCopy = () => {
        if (typeof text === "string") {
            navigator.clipboard.writeText(text).then(r => {
                setCopySuccess(true)
                setTimeout(() => setCopySuccess(false), 2000)
            })
        }
    }

    return <>
        {text && <div
            className={clsx('text-gray-600 fs-sm-6',
                {
                    'fw-bolder': bold,
                    'fs-5': bold,
                }
            )}
        >
            <span className={clsx('fw-900',
                {
                    'd-none d-md-inline': prefixSmDisplayNone,
                }
            )}
            >{prefix && prefix}</span>
            {text}
            <span>{appendix && appendix}</span>
            {
                copyBtn &&
                <button className="btn btn-white px-3 py-0" onClick={handleCopy}>
                    {
                        copySuccess
                            ?
                            <KTSVG path="media/icons/duotune/general/gen037.svg"
                                   className="svg-icon-primary svg-icon-1"/>
                            :
                            <KTSVG path="media/icons/duotune/general/gen054.svg" className="svg-icon-muted svg-icon-1"/>

                    }
                </button>
            }
        </div>}
    </>
}

export {TextCell}
