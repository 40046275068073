import React, {useEffect} from 'react'
import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {UsersTable} from "../../modules/tables/users/UsersTable";
import {RadiusTable} from "../../modules/tables/radius/RadiusTable";

const RadiusPage = () => {

    const breadcrumbs = [
        {
            title: 'پنل ادمین',
            path: "/admin",
            isActive: true
        },
        {
            title: 'مدیریت سرورهای ردیوس',
            path: "/admin/users",
            isActive: true
        }
    ]
    useEffect(() => {
        document.title = 'مدیریت سرورهای ردیوس'
    }, []);
    return (
        <EnableSidebar>
            <PageTitle breadcrumbs={breadcrumbs}>
                مدیریت سرورهای ردیوس
            </PageTitle>
            <RadiusTable/>
        </EnableSidebar>
    )
}

export {RadiusPage}
