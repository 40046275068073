import {useQuery} from 'react-query'
import {RadiusEditModalForm} from './RadiusEditModalForm'
import {useListView} from "../RadiusListViewProvider";
import {isNotEmpty, QUERIES} from "../../../../../_metronic/helpers";
import {getRadiusById} from "../../../../requests/_admin_requests";

const RadiusEditModalFormWrapper = () => {
    const {itemIdForUpdate, setItemIdForUpdate} = useListView()
    const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
    const {
        isLoading,
        data,
        error,
    } = useQuery(
        `${QUERIES.RADIUS_LIST}-radius-${itemIdForUpdate}`,
        () => {
            return getRadiusById(itemIdForUpdate)
        },
        {
            cacheTime: 0,
            enabled: enabledQuery,
            onError: (err) => {
                setItemIdForUpdate(undefined)
                console.error(err)
            },
        }
    )

    if (!itemIdForUpdate) {
        return <RadiusEditModalForm isLoading={isLoading} data={{id: undefined}}/>
    }

    if (!isLoading && !error && data) {
        return <RadiusEditModalForm isLoading={isLoading} data={data}/>
    }

    return null
}

export {RadiusEditModalFormWrapper}
