// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {User} from '../../core/_models'

type Props = {
    row: Row<User>
}

const ListRow: FC<Props> = ({row}) => (
    <tr {...row.getRowProps()} className="card bg-gray-100 border-bottom-3 my-5">
        {row.cells.map((cell) => {
            return (
                <div
                    {...cell.getCellProps()}
                    className={clsx('p-2 subscription-list-item', {
                        'subscription-actions': cell.column.id === 'actions',
                        'top-left': cell.column.type === 'top-left',
                        'second-top-left': cell.column.type === 'second-top-left',
                        'subscription-right-100 position-relative': cell.column.type === 'right-100',
                        'float-none float-md-start position-md-absolute top-25': cell.column.id === 'status'
                    })}
                >
                    {cell.render('Cell')}
                </div>
            )
        })}
    </tr>
)

export {ListRow}
