import {AuthModel} from './_models'
import {useLocation} from "react-router-dom";

const AUTH_LOCAL_STORAGE_KEY = 'ct-tok'
const ADMIN_AUTH_LOCAL_STORAGE_KEY = 'ct-adm'
const getAuth = (): AuthModel | undefined => {
    if (!localStorage) {
        return
    }

    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    if (!lsValue) {
        return
    }

    try {
        const auth: AuthModel = JSON.parse(lsValue) as AuthModel
        if (auth) {
            // You can easily check auth_token expiration also
            return auth
        }
    } catch (error) {
        console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    }
}

const adminGetAuth = (): AuthModel | undefined => {
    if (!localStorage) {
        return
    }

    const lsValue: string | null = localStorage.getItem(ADMIN_AUTH_LOCAL_STORAGE_KEY)
    if (!lsValue) {
        return
    }

    try {
        const auth: AuthModel = JSON.parse(lsValue) as AuthModel
        if (auth) {
            // You can easily check auth_token expiration also
            return auth
        }
    } catch (error) {
        console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    }
}

const setAuth = (auth: AuthModel) => {
    if (!localStorage) {
        return
    }

    try {
        const lsValue = JSON.stringify(auth)
        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
    } catch (error) {
        console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
    }
}

const adminSetAuth = (auth: AuthModel) => {
    if (!localStorage) {
        return
    }

    try {
        const lsValue = JSON.stringify(auth)
        localStorage.setItem(ADMIN_AUTH_LOCAL_STORAGE_KEY, lsValue)
    } catch (error) {
        console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
    }
}

const removeAuth = () => {
    if (!localStorage) {
        return
    }

    try {
        localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
    } catch (error) {
        console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
    }
}

const adminRemoveAuth = () => {
    if (!localStorage) {
        return
    }

    try {
        localStorage.removeItem(ADMIN_AUTH_LOCAL_STORAGE_KEY)
    } catch (error) {
        console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
    }
}

export function setupAxios(axios: any) {
    axios.defaults.headers.Accept = 'application/json'
    axios.interceptors.request.use(
        (config: { headers: { Authorization: string } }) => {
            let auth = getAuth()

            if (window.location.pathname.includes('/admin')) {
                auth = adminGetAuth()
            }

            if (auth && auth.api_token) {
                config.headers.Authorization = `Bearer ${auth.api_token}`
            }

            return config
        },
        (err: any) => Promise.reject(err)
    )
}

export {
    getAuth,
    setAuth,
    removeAuth,
    AUTH_LOCAL_STORAGE_KEY,
    adminGetAuth,
    adminSetAuth,
    adminRemoveAuth,
    ADMIN_AUTH_LOCAL_STORAGE_KEY
}
