/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React from 'react'
import {Link} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Topbar} from './Topbar'
import {useAuth} from "../../../../app/modules/auth";
import {AdminTopbar} from "./AdminTopbar";

export function HeaderWrapper() {
    const {config, classes, attributes} = useLayout()
    const {adminUser} = useAuth()

    return (
        <div
            id='kt_header'
            className={clsx('header bg-primary', classes.header.join(' '), 'align-items-stretch')}
            {...attributes.headerMenu}
        >
            <div
                className={clsx(
                    classes.headerContainer.join(' '),
                    'd-flex align-items-stretch justify-content-between'
                )}
            >

                <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 w-lg-225px me-5'>
                    <div
                        className='btn btn-icon btn-active-icon-light ms-n2 me-2 d-flex d-lg-none'
                        id='kt_aside_toggle'
                    >
                        <KTIcon iconName='abstract-14' className='fs-1 svg-icon svg-icon-white'/>
                    </div>

                    <Link to='/' className="px-lg-10 mx-auto">
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/logo-white.svg')}
                            className='d-none d-lg-inline mh-65px theme-light-show'
                        />
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/logo-white.svg')}
                            className='d-lg-none mh-50px'
                        />
                    </Link>
                </div>

                {adminUser ? <AdminTopbar/> : <Topbar/>}

            </div>
        </div>
    )
}
