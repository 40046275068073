import {useQuery} from 'react-query'
import {CouponEditModalForm} from './CouponEditModalForm'
import {useListView} from "../CouponListViewProvider";
import {isNotEmpty, QUERIES} from "../../../../../_metronic/helpers";
import {getCouponById} from "../../../../requests/_admin_requests";

const CouponEditModalFormWrapper = () => {
    const {itemIdForUpdate, setItemIdForUpdate} = useListView()
    const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
    const {
        isLoading,
        data: coupon,
        error,
    } = useQuery(
        `${QUERIES.COUPON_LIST}-coupon-${itemIdForUpdate}`,
        () => {
            return getCouponById(itemIdForUpdate)
        },
        {
            cacheTime: 0,
            enabled: enabledQuery,
            onError: (err) => {
                setItemIdForUpdate(undefined)
                console.error(err)
            },
        }
    )

    if (!itemIdForUpdate) {
        // @ts-ignore
        return <CouponEditModalForm isCouponLoading={isLoading} coupon={{id: "" }}/>
    }

    if (!isLoading && !error && coupon) {
        return <CouponEditModalForm isCouponLoading={isLoading} coupon={coupon}/>
    }

    return null
}

export {CouponEditModalFormWrapper}
