import {useQuery} from 'react-query'
import {SettingEditModalForm} from './SettingEditModalForm'
import {adminGetSingleSetting} from "../../../../requests/_admin_requests";
import {useListView} from "../SettingListViewProvider";
import {isNotEmpty} from "../../../../../_metronic/helpers";

const SettingEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: setting,
    error,
  } = useQuery(
    `setting-${itemIdForUpdate}`,
    () => {
      return adminGetSingleSetting(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <SettingEditModalForm isSettingLoading={isLoading} setting={{key: ""}} />
  }

  if (!isLoading && !error && setting) {
    return <SettingEditModalForm isSettingLoading={isLoading} setting={setting?.data?.data} />
  }

  return null
}

export {SettingEditModalFormWrapper}
