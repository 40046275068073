/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {Questions} from '../../modules/apps/dev/components/partials/Questions'
import {useIntl} from "react-intl";
import Dashboard from "./Dashboard";

const tr = {
    homepage: {
        id: 'HOMEPAGE'
    },
    dashboard: {
        id: 'DASHBOARD'
    },
}
const DashboardWrapper: FC = () => {
    const {formatMessage: text} = useIntl()

    const breadcrumbs = [
        {
            title: text(tr.homepage),
            path: "/",
            isActive: true
        },
        {
            title: text(tr.dashboard),
            path: "/dashboard",
            isActive: true
        }
    ]
    return (
        <EnableSidebar>
            <PageTitle breadcrumbs={breadcrumbs}>
                {text(tr.dashboard)}
            </PageTitle>
            <Dashboard/>
        </EnableSidebar>
    )
}

export {DashboardWrapper}
