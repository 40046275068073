// @ts-nocheck
import {Column} from 'react-table'
import {UserCustomHeader} from "../../apps/user-management/users-list/table/columns/UserCustomHeader";
import {ColumnHeader} from "../columns/ColumnHeader";
import {TextCell} from "../columns/TextCell";
import {BadgeCell} from "../columns/BadgeCell";
import {RadiusActionCell} from "./RadiusActionCell";
import {StatusCell} from "../columns/StatusCell";
import {Radius} from "../../../_models";

const RadiusColumns: ReadonlyArray<Column<Radius>> = [
    {
        Header: (props) => <ColumnHeader tableProps={props} title='ID' className='min-w-50px'/>,
        id: 'id',
        Cell: ({...props}) => <BadgeCell text={props.data[props.row.index]?.id}/>,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='نام نمایشی' className='min-w-100px'/>,
        id: 'name',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.name} bold={true}/>,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='وضعیت' className='min-w-125px' />,
        id: 'status',
        Cell: ({...props}) => <StatusCell is_active={props?.data[props.row.index]?.is_active} bold={true} />,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='هاست' className='min-w-100px'/>,
        id: 'host',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.host} bold={true}/>,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='پورت' className='min-w-100px'/>,
        id: 'port',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.port} bold={true}/>,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='تاریخ ایجاد' className='min-w-100px'/>,
        id: 'created_at',
        Cell: ({...props}) => <TextCell text={props.data[props.row.index]?.created_at}/>,
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='عملیات' className='text-end min-w-100px'/>
        ),
        id: 'actions',
        Cell: ({...props}) => <RadiusActionCell id={props.data[props.row.index].id}/>,
    },
]

export {RadiusColumns}
