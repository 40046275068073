import React, {useEffect} from 'react'
import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {KTCard} from "../../../_metronic/helpers";
import {AdminSubsTable} from "../../modules/tables/adminSubs/AdminSubsTable";
import {Alert} from "../../modules/tables/users/Alert";

const AdminSubsPage = () => {

    const breadcrumbs = [
        {
            title: 'پنل ادمین',
            path: "/admin",
            isActive: true
        },
        {
            title: 'مدیریت اشتراک ها',
            path: "/admin/subscriptions",
            isActive: true
        }
    ]
    useEffect(() => {
        document.title = 'مدیریت اشتراک ها'
    }, []);
    return (
        <EnableSidebar>
            <PageTitle breadcrumbs={breadcrumbs}>
                مدیریت اشتراک ها
            </PageTitle>

            <AdminSubsTable/>
        </EnableSidebar>
    )
}

export {AdminSubsPage}
