import {FC, useState, useEffect, createContext, useContext, Dispatch, SetStateAction} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from '../../../requests/_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import {getAdmin} from "../../../requests/_admin_requests";

type AuthContextProps = {
    auth: AuthModel | undefined
    adminAuth: AuthModel | undefined
    saveAuth: (auth: AuthModel | undefined) => void
    adminSaveAuth: (auth: AuthModel | undefined) => void
    currentUser: UserModel | undefined
    setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
    logout: () => void
    adminLogout: () => void
    phone: string | undefined
    setPhone: Dispatch<SetStateAction<string | undefined>>
    adminUser: UserModel | undefined
    setAdminUser: Dispatch<SetStateAction<UserModel | undefined>>
}

const initAuthContextPropsState = {
    auth: authHelper.getAuth(),
    adminAuth: authHelper?.adminGetAuth(),
    saveAuth: () => {
    },
    adminSaveAuth: () => {
    },
    currentUser: undefined,
    setCurrentUser: () => {
    },
    logout: () => {
    },
    adminLogout: () => {
    },
    phone: undefined,
    setPhone: () => {
    },
    adminUser: undefined,
    setAdminUser: () => {
    },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
    return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
    const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
    const [adminAuth, adminSetAuth] = useState<AuthModel | undefined>(authHelper.adminGetAuth())
    const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
    const [adminUser, setAdminUser] = useState<UserModel | undefined>()
    const [phone, setPhone] = useState<string | undefined>()
    const saveAuth = (auth: AuthModel | undefined) => {
        setAuth(auth)
        if (auth) {
            authHelper.setAuth(auth)
        } else {
            authHelper.removeAuth()
        }
    }

    const adminSaveAuth = (auth: AuthModel | undefined) => {
        adminSetAuth(auth)
        if (auth) {
            authHelper.adminSetAuth(auth)
        } else {
            authHelper.adminRemoveAuth()
        }
    }

    const logout = () => {
        saveAuth(undefined)
        setCurrentUser(undefined)
        setPhone(undefined)
    }

    const adminLogout = () => {
        adminSaveAuth(undefined)
        setAdminUser(undefined)
    }

    return (
        <AuthContext.Provider
            value={{auth, saveAuth, currentUser, setCurrentUser, logout, phone, setPhone, adminUser, setAdminUser, adminLogout, adminSaveAuth, adminAuth}}>
            {children}
        </AuthContext.Provider>
    )
}

const AuthInit: FC<WithChildren> = ({children}) => {
    const {auth, currentUser, logout, setCurrentUser} = useAuth()
    const [showSplashScreen, setShowSplashScreen] = useState(true)

    // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
    useEffect(() => {
        const requestUser = async (apiToken: string) => {
            try {
                if (!currentUser) {
                    const {data} = await getUserByToken(apiToken)
                    if (data) {
                        setCurrentUser(data.data)
                    }
                }
            } catch (error) {
                if (currentUser) {
                    logout()
                }
            } finally {
                setShowSplashScreen(false)
            }
        }

        if (auth && auth.api_token) {
            requestUser(auth.api_token)
        } else {
            logout()
            setShowSplashScreen(false)
        }
        // eslint-disable-next-line
    }, [])

    return showSplashScreen ? <LayoutSplashScreen/> : <>{children}</>
}
const AdminAuthInit: FC<WithChildren> = ({children}) => {
    const {adminAuth, adminUser, adminLogout, setAdminUser} = useAuth()
    const [showSplashScreen, setShowSplashScreen] = useState(true)

    // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
    useEffect(() => {
        const requestAdmin = async (apiToken: string) => {
            try {
                if (!adminUser) {
                    const {data} = await getAdmin(apiToken)
                    if (data) {
                        setAdminUser(data.data)
                    }
                }
            } catch (error) {
                if (adminUser) {
                    adminLogout()
                }
            } finally {
                setShowSplashScreen(false)
            }
        }

        if (adminAuth && adminAuth.api_token) {
            requestAdmin(adminAuth.api_token).catch((e)=>{
                if (e.statusCode === 401){
                    adminLogout()
                }
            })
        } else {
            adminLogout()
            setShowSplashScreen(false)
        }
        // eslint-disable-next-line
    }, [])

    return showSplashScreen ? <LayoutSplashScreen/> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth, AdminAuthInit}
