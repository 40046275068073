import React from "react";
import {useIntl} from "react-intl";
import {useAuth} from "../../core/Auth";

const tr = {
    subtitle: {
        id: "AUTH.SUBTITLE"
    }
};

const AuthSubtitle = () => {
    const {formatMessage: text} = useIntl();
    const {phone} = useAuth()

    return <div className='text-gray-500 fw-semibold fs-6'>
        {text(tr.subtitle, {phone})}
    </div>
}

export default AuthSubtitle;