/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {Link, NavLink} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from "../../../helpers";
import {useAuth} from "../../../../app/modules/auth";

const AsideDefault: FC = () => {
    const {config, classes} = useLayout()
    const {aside} = config
    const {currentUser, logout} = useAuth()

    return (
        <div
            id='kt_aside'
            className={clsx('aside', classes.aside.join(' '), {'d-none': !aside.display})}
            data-kt-drawer='true'
            data-kt-drawer-name='aside'
            data-kt-drawer-activate='{default: true, lg: false}'
            data-kt-drawer-overlay='true'
            data-kt-drawer-width="{default:'200px', '300px': '225px'}"
            data-kt-drawer-direction='start'
            data-kt-drawer-toggle='#kt_aside_toggle'
        >
            <div className='card my-lg-5 w-100 p-5'>
                <div className="d-flex py-10 flex-column justify-content-between align-items-center">
                    <img alt='avatar' src={toAbsoluteUrl('/media/UserProfilePicture.png')} width={74}/>
                    <h6 className="text-gray-500 my-2 mt-5">{currentUser?.name}</h6>
                    <h6>{currentUser?.phone}</h6>
                </div>
                <div
                    className='menu menu-column gap-5 menu-active-bg menu-hover-bg menu-title-gray-700 fs-6 menu-rounded w-100'
                    id='#kt_aside_menu'
                    data-kt-menu='true'
                >
                    <div className='menu-item'>
                        <NavLink to='/dashboard' className='menu-link bg-gray-100'>
                            <span className='menu-title'>داشبورد</span>
                        </NavLink>
                    </div>

                    <div className='menu-item'>
                        <NavLink to='/subscriptions' className='menu-link bg-gray-100'>
                            <span className='menu-title'>مدیریت اشتراک‌ها</span>
                        </NavLink>
                    </div>

                    <div className='menu-item'>
                        <NavLink to='/instructions' className='menu-link bg-gray-100'>
                            <span className='menu-title'>آموزش، اپلیکیشن ها، سرورها</span>
                        </NavLink>
                    </div>

                    <div className='menu-item'>
                        <NavLink to='/transactions' className='menu-link bg-gray-100'>
                            <span className='menu-title'>تاریخچه پرداخت</span>
                        </NavLink>
                    </div>

                    <div className='menu-item'>
                        <NavLink to='/support' className='menu-link bg-gray-100'>
                            <span className='menu-title'>پشتیبانی آنلاین</span>
                        </NavLink>
                    </div>

                    <div className='menu-item'>
                        <NavLink to='/profile' className='menu-link bg-gray-100'>
                            <span className='menu-title'>اطلاعات حساب کاربری</span>
                        </NavLink>
                    </div>

                    <div className='menu-item'>
                        <NavLink to='/auth/otp' onClick={logout} className='menu-link bg-gray-100'>
                            <span className='menu-title'>خروج از حساب کاربری</span>
                        </NavLink>
                    </div>

                </div>
            </div>
        </div>
    )
}

export {AsideDefault}
