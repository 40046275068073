// @ts-nocheck
import {Column} from 'react-table'
import {Plan} from "../../../_models";
import {UserCustomHeader} from "../../apps/user-management/users-list/table/columns/UserCustomHeader";
import {ColumnHeader} from "../columns/ColumnHeader";
import {TextCell} from "../columns/TextCell";
import {BadgeCell} from "../columns/BadgeCell";
import {PlanActionsCell} from "../columns/PlanActionsCell";

export const PlanColumns: ReadonlyArray<Column<Plan>> = [
    {
        Header: (props) => <ColumnHeader tableProps={props} title='ID' className='min-w-125px'/>,
        id: 'id',
        Cell: ({...props}) => <BadgeCell text={props.data[props.row.index]?.id}/>,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='نام پلن' className='min-w-125px'/>,
        id: 'name',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.name} bold={true}/>,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='قیمت' className='min-w-125px'/>,
        id: 'price',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.price} bold={true}/>,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='پیشوند نام کاربری' className='min-w-125px'/>,
        id: 'prefix',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.random_username_prefix} bold={true}/>,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='توضیحات' className='min-w-125px'/>,
        id: 'description',
        Cell: ({...props}) => <TextCell text={props.data[props.row.index]?.description}/>,
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='عملیات' className='text-end min-w-100px'/>
        ),
        id: 'actions',
        Cell: ({...props}) => <PlanActionsCell id={props.data[props.row.index].id}/>,
    },
]
