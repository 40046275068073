import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AdminAuthInit} from './modules/auth'
import {ThemeModeProvider} from '../_metronic/partials'

const AdminApp = () => {
  return (
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <AdminAuthInit>
              <Outlet />
              <MasterInit />
            </AdminAuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {AdminApp}
