import React from "react";

const ErrorAlert = ({touched, message}) => {
    return <>
        {touched && message && (
            <div
                className='fv-plugins-message-container ml-0 card bg-danger-subtle border-dashed border-danger p-2'>
                <span role='alert'>{message}</span>
            </div>
        )}
    </>
}
export default ErrorAlert;
