import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import AuthSubtitle from "../../auth/components/partials/AuthSubtitle";
import React, {useEffect, useState} from "react";
import {getOpenInvoice, payInvoice} from "../../../requests/_requests";
import {InvoiceModel} from "../../auth";

const tr = {
    previous: {
        id: 'PREVIOUS'
    },
    title: {
        id: 'PREPAY.TITLE'
    },
    dear: {
        id: 'DEAR'
    },
    user: {
        id: 'USER'
    },
    selectedSub: {
        id: 'PREPAY.SELECT_SUBSCRIPTION'
    },
    desc: {
        id: 'PREPAY.DESC'
    },
    username: {
        id: 'ACCOUNT.USERNAME'
    },
    password: {
        id: 'ACCOUNT.PASSWORD'
    },
    price: {
        id: 'PREPAY.PRICE'
    },
    payable: {
        id: 'PREPAY.PAYABLE'
    },
    discount: {
        id: 'PREPAY.DISCOUNT'
    },
    tax: {
        id: 'PREPAY.TAX'
    },
    pay: {
        id: 'PREPAY.PAY'
    },
}

export function PrePay() {
    const {formatMessage: text} = useIntl()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(false)
    const [message, setMessage] = useState("")
    const [invoice, setInvoice] = useState<InvoiceModel>()

    useEffect(() => {
        setLoading(false)
        getOpenInvoice()
            .then((res) => {
                setInvoice(res.data.data)
            })
    }, [])

    const paymentRequest = () => {
        if (invoice) {
            setAlert(false)
            setLoading(true)
            payInvoice(invoice.id.toString())
                .then(res => {
                    if (res?.data?.data?.redirect_url) {
                        window.location.assign(res.data.data.redirect_url);
                    }
                })
                .catch((res) => {
                    setLoading(false)
                    setAlert(true)
                    setMessage(res.response?.data?.error?.message)
                })
        }
    }

    return (
        <div className='w-100'>
            {/* begin::Heading */}
            <h1 className='text-dark fw-bolder'>
                {text(tr.title)}
            </h1>
            <AuthSubtitle/>
            {/* end::Heading */}

            <div className='card card-custom card-flush my-5'>
                {invoice ?
                    <div className="card-body">

                        {/* begin::Form group */}
                        <h3 className="fw-bold">
                            {text(tr.user)}{text(tr.dear)}
                        </h3>
                        <p>
                            {text(tr.desc)}
                        </p>
                        <div className="d-flex flex-row justify-content-between align-items-center my-10">
                            <h6>{text(tr.selectedSub)}</h6>
                            <div className="card bg-primary text-white p-2">{invoice.accounts[0].plan.name}</div>
                        </div>
                        {/* end::Form group */}

                        {/* begin::Form group */}
                        <div className="d-flex flex-row justify-content-between gap-5 my-10">
                            <div className="w-50">
                                <label className="form-label text-gray-400">
                                    {text(tr.username)}
                                </label>
                                <input
                                    disabled={true}
                                    className='form-control'
                                    value={invoice.accounts[0].username}
                                />
                            </div>
                            <div className="w-50">
                                <label className="form-label text-gray-400">
                                    {text(tr.password)}
                                </label>
                                <input
                                    disabled={true}
                                    className='form-control'
                                    value={invoice.accounts[0].password}
                                />
                            </div>
                        </div>
                        {/* end::Form group */}

                        <hr/>

                        <div className="d-flex flex-row justify-content-between align-items-center text-nowrap my-5">
                            <h6>{text(tr.price)}</h6>
                            <div
                                className="w-100 m-3 border-top-1 border-dashed border-bottom-0 border-right-0 border-left-0"/>
                            <div
                                className="card bg-secondary-subtle d-inline-block p-2 flex-shrink-0">{invoice.total_amount}</div>
                        </div>

                        <div className="d-flex flex-row justify-content-between align-items-center text-nowrap my-5">
                            <h6>{text(tr.tax)}</h6>
                            <div
                                className="w-100 m-3 border-top-1 border-dashed border-bottom-0 border-right-0 border-left-0"/>
                            <div
                                className="card bg-secondary-subtle d-inline-block p-2 flex-shrink-0">{invoice.tax}</div>
                        </div>

                        <div className="d-flex flex-row justify-content-between align-items-center text-nowrap my-5">
                            <h6>{text(tr.discount)}</h6>
                            <div
                                className="w-100 m-3 border-top-1 border-dashed border-bottom-0 border-right-0 border-left-0"/>
                            <div
                                className="card bg-secondary-subtle d-inline-block p-2 flex-shrink-0">{invoice.discount}</div>
                        </div>

                        <div className="d-flex flex-row justify-content-between align-items-center text-nowrap my-5">
                            <h6>{text(tr.payable)}</h6>
                            <div
                                className="w-100 m-3 border-top-1 border-dashed border-bottom-0 border-right-0 border-left-0"/>
                            <div
                                className="card bg-secondary-subtle d-inline-block p-2 flex-shrink-0">{invoice.payable}</div>
                        </div>

                    </div>
                    :
                    <div></div>
                }

            </div>

            {alert &&
                <div className="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10">
                    {/*<span className="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">...</span>*/}
                    <div className="d-flex flex-column text-primary pe-0 pe-sm-10">
                        <h5 className="mb-1">خطا در اتصال به درگاه</h5>
                        <span>{message ?? "خطا در اتصال به درگاه"}</span>
                    </div>

                    <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                        <span className="svg-icon svg-icon-1 svg-icon-primary">X</span>
                    </button>
                </div>

            }

            {/* begin::Action */}
            <div className='d-flex justify-content-between mb-10'>
                <button onClick={() => navigate(-1)}
                        className='btn btn-outline btn-outline-primary'>
                        <span className='indicator-label'>
                            {text(tr.previous)}
                        </span>
                </button>

                <button
                    className='btn btn-primary'
                    disabled={loading}
                >
                    {!loading &&
                        <span onClick={paymentRequest} className='indicator-label'>
                            {text(tr.pay)}
                        </span>}
                    {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                            {text(tr.pay)}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            {/* end::Action */}

        </div>
    );
}