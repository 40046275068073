import React from "react";

const InvoiceItem = ({name, value}) => {
    return (
        <div className="d-flex flex-row justify-content-between align-items-center text-nowrap my-5">
            <h6>{name}</h6>
            <div
                className="w-100 m-3 border-top-1 border-dashed border-bottom-0 border-right-0 border-left-0"/>
            <div
                className="card bg-secondary-subtle d-inline-block p-2 flex-shrink-0">{value}</div>
        </div>
    )
}

export default InvoiceItem