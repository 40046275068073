import React, {useEffect, useState} from 'react'
import {KTIcon} from "../../../../../_metronic/helpers";
import {useListView} from "../SubListViewProvider";
import {getInvoiceFromSub} from "../../../../requests/_requests";
import {Invoice} from "../../../../_models";
import {useIntl} from "react-intl";
import {ListLoading} from "../../ListLoading";

const tr = {
    previous: {
        id: 'PREVIOUS'
    },
    title: {
        id: 'PREPAY.TITLE'
    },
    dear: {
        id: 'DEAR'
    },
    user: {
        id: 'USER'
    },
    selectedSub: {
        id: 'PREPAY.SELECT_SUBSCRIPTION'
    },
    desc: {
        id: 'PREPAY.DESC'
    },
    username: {
        id: 'ACCOUNT.USERNAME'
    },
    password: {
        id: 'ACCOUNT.PASSWORD'
    },
    price: {
        id: 'PREPAY.PRICE'
    },
    payable: {
        id: 'PREPAY.PAYABLE'
    },
    discount: {
        id: 'PREPAY.DISCOUNT'
    },
    tax: {
        id: 'PREPAY.TAX'
    },
    pay: {
        id: 'PREPAY.PAY'
    },
}

const ShowInvoiceModal = (props) => {
    const {setShow, show} = useListView()
    const [invoice, setInvoice] = useState<Invoice>()
    const {formatMessage: text} = useIntl()

    useEffect(() => {
        getInvoiceFromSub(show).then(res => {
            if (res){
                setInvoice(res)
            }
        }).catch(e => {
            if (setShow) {
                setShow(undefined)
            }

            //todo alert user with the error message

        })
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-650px'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>خرید اشتراک جدید</h2>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => {
                                    if (setShow) {
                                        setShow(undefined)
                                    }
                                }}
                                style={{cursor: 'pointer'}}
                            >
                                <KTIcon iconName='cross' className='fs-1' />
                            </div>
                            {/* end::Close */}
                        </div>
                        {/* begin::Modal body */}
                        <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                            {invoice ?
                                <div className="card-body">

                                    <div className="d-flex flex-row justify-content-between align-items-center text-nowrap my-5">
                                        <h6>{text(tr.price)}</h6>
                                        <div
                                            className="w-100 m-3 border-top-1 border-dashed border-bottom-0 border-right-0 border-left-0"/>
                                        <div
                                            className="card bg-secondary-subtle d-inline-block p-2 flex-shrink-0">{invoice.total_amount}</div>
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center text-nowrap my-5">
                                        <h6>{text(tr.tax)}</h6>
                                        <div
                                            className="w-100 m-3 border-top-1 border-dashed border-bottom-0 border-right-0 border-left-0"/>
                                        <div
                                            className="card bg-secondary-subtle d-inline-block p-2 flex-shrink-0">{invoice.tax}</div>
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center text-nowrap my-5">
                                        <h6>{text(tr.discount)}</h6>
                                        <div
                                            className="w-100 m-3 border-top-1 border-dashed border-bottom-0 border-right-0 border-left-0"/>
                                        <div
                                            className="card bg-secondary-subtle d-inline-block p-2 flex-shrink-0">{invoice.discount}</div>
                                    </div>

                                    <div className="d-flex flex-row justify-content-between align-items-center text-nowrap my-5">
                                        <h6>{text(tr.payable)}</h6>
                                        <div
                                            className="w-100 m-3 border-top-1 border-dashed border-bottom-0 border-right-0 border-left-0"/>
                                        <div
                                            className="card bg-secondary-subtle d-inline-block p-2 flex-shrink-0">{invoice.payable}</div>
                                    </div>

                                </div>
                                :
                                <ListLoading/>
                            }

                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export {ShowInvoiceModal}
