import React, {useEffect, useState} from 'react'
import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {KTCard, KTCardBody, toAbsoluteUrl} from "../../../_metronic/helpers";
import {SubscriptionPageList} from "../../modules/tables/userSubs/SubscriptionPageList";
import {RenewSubModal} from "../../modules/tables/userSubs/subs-modal/RenewSubModal";
import {NewSubModal} from "../../modules/tables/userSubs/subs-modal/NewSubModal";

const SubscriptionPage = () => {

    const [newModal, setNewModal] = useState(false)

    const breadcrumbs = [
        {
            title: 'پنل کاربری',
            path: "/",
            isActive: true
        },
        {
            title: 'مدیریت اشتراک ها',
            path: "/subscriptions",
            isActive: true
        }
    ]

    useEffect(() => {
        document.title = 'مدیریت اشتراک ها'
    }, []);

    return (
        <EnableSidebar>
            <PageTitle breadcrumbs={breadcrumbs}>
                مدیریت اشتراک های شما
            </PageTitle>
            <div className="w-75">
                از این بخش می‌توانید اشتراک هایی که تهیه کرده اید را مدیریت کنید، بعنوان مثال می‌توانید یک اشتراک را
                تمدید کنید، رمز عبور اتصال آن را تغییر دهید و از وضعیت کلی آن مطلع شوید. همچنین در صورت نیاز
                می‌توانید
                به تعداد نامحدود اشتراک جدید تهیه کنید.
            </div>
            <KTCard className='my-5 bg-gray-100'>
                <div className="p-10 pb-0">
                    <h2 className="text-primary">خرید اشتراک جدید</h2>
                </div>
                <KTCardBody className='py-4'>
                    <div className='d-flex'>
                        <div className='w-75 w-100'>
                            <p> شما با داشتن یک حساب کاربری می‌توانید به تعداد دلخواه خود اشتراک تهیه کنید و
                                محدودیتی در
                                خرید اشتراک برای شما وجود ندارد. بعنوان مثال می‌توانید یک اشتراک برای خودتان و یک
                                اشتراک
                                برای دوست یا خانواده خود تهیه کنید و هر کدام از این اشتراک ها نام کاربری و رمز عبور
                                متفاوتی دارند.</p>
                            <button
                                onClick={() => setNewModal(true)}
                                className="btn btn-primary btn-sm">خرید اشتراک جدید
                            </button>
                        </div>
                        <div className='w-md-25'>
                            <img
                                className='position-md-absolute d-none d-md-flex'
                                style={{top: "-50px"}}
                                src={toAbsoluteUrl('/media/girl-phone.svg')}
                                alt='اشتراک ها'
                            />
                        </div>
                    </div>
                </KTCardBody>
            </KTCard>

            {newModal && <NewSubModal newModal={newModal} setNewModal={setNewModal}/>}

            <KTCard className='my-5 bg-gray-100'>
                <div className="p-10 pb-0">
                    <h2 className="text-primary">مدیریت اشتراک ها</h2>
                </div>
                <SubscriptionPageList/>
            </KTCard>
        </EnableSidebar>
    )
}

export {SubscriptionPage}
