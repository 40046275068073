/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {
    createResponseContext,
    initialQueryResponse,
    initialQueryState,
    PaginationState,
    QUERIES,
    stringifyRequestQuery,
    WithChildren
} from "../../../../_metronic/helpers";
import {Radius} from "../../../_models";
import {getRadius} from "../../../requests/_admin_requests";
import {useQueryRequest} from "../core/QueryRequestProvider";

const QueryResponseContextRadius = createResponseContext<Radius>(initialQueryResponse)
const RadiusQueryResponseProvider: FC<WithChildren> = ({children}) => {
    const {state} = useQueryRequest()
    const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
    const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

    useEffect(() => {
        if (query !== updatedQuery) {
            setQuery(updatedQuery)
        }
    }, [updatedQuery])

    const {
        isFetching,
        refetch,
        data: response,
    } = useQuery(
        `${QUERIES.RADIUS_LIST}-${query}`,
        () => {
            return getRadius(query)
        },
        {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
    )

    return (
        <QueryResponseContextRadius.Provider value={{isLoading: isFetching, refetch, response, query}}>
            {children}
        </QueryResponseContextRadius.Provider>
    )
}

const useQueryResponseRadius = () => useContext(QueryResponseContextRadius)

const useQueryResponseDataRadius = () => {
    const {response} = useQueryResponseRadius()

    if (!response) {
        return []
    }

    return response?.data || []
}

const useQueryResponsePagination = () => {
    const defaultPaginationState: PaginationState = {
        links: [],
        ...initialQueryState,
    }

    const {response} = useQueryResponseRadius()

    if (!response || !response?.meta) {
        return defaultPaginationState
    }

    return response.meta
}

const useQueryResponseLoading = (): boolean => {
    const {isLoading} = useQueryResponseRadius()
    return isLoading
}

export {
    RadiusQueryResponseProvider,
    useQueryResponsePagination,
    useQueryResponseLoading,
    useQueryResponseDataRadius,
    useQueryResponseRadius
}
