// @ts-nocheck
import {Column} from 'react-table'
import {ColumnHeader} from '../columns/ColumnHeader'
import {Invoice} from "../../../_models";
import {TextCell} from "../columns/TextCell";
import {BadgeCell} from "../columns/BadgeCell";

const InvoiceColumns: ReadonlyArray<Column<Invoice>> = [
    {
        Header: (props) => <ColumnHeader tableProps={props} title='کد پرداخت' className='min-w-125px' />,
        id: 'trans_id',
        Cell: ({...props}) => <BadgeCell text={props.data[props.row.index]?.trans_id} />,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='نوع اشتراک' className='min-w-125px' />,
        id: 'plan_name',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.account?.plan_name} bold={true} />,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='مبلغ پرداختی' className='min-w-125px' />,
        id: 'payable',
        Cell: ({...props}) => <TextCell text={props?.data[props.row.index]?.payable} bold={true} />,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='نام کاربری' className='min-w-125px' />,
        id: 'username',
        Cell: ({...props}) => <TextCell text={props.data[props.row.index]?.account?.username} />,
    },
    {
        Header: (props) => <ColumnHeader tableProps={props} title='تاریخ پرداخت' className='min-w-125px' />,
        id: 'payment_at',
        Cell: ({...props}) => <TextCell text={props.data[props.row.index]?.payment_at ?? 'پرداخت نشده'} />,
    },
]

export {InvoiceColumns}
