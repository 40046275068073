import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Subscription} from "../../../../../_models";
import {useListView} from "../../SubListViewProvider";
import {useQueryResponseSub} from "../../SubsQueryResponseProvider";
import clsx from "clsx";
import {ListLoading} from "../../../ListLoading";
import {updateSubscriptionAuth} from "../../../../../requests/_requests";

type Props = {
    isLoading: boolean
    sub: Subscription
}

const editUserSchema = Yup.object().shape({
    password: Yup.string()
        .min(4, 'رمز عبور باید حداقل ۴ کاراکتر باشد.')
        .required('وارد کردن رمز عبور ضروری است.'),
})

const SubEditModalForm: FC<Props> = ({sub, isLoading}) => {
    const {setItemIdForUpdate} = useListView()
    const {refetch} = useQueryResponseSub()

    const [userForEdit] = useState<Subscription>({
        ...sub
    })

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch()
        }
        setItemIdForUpdate(undefined)
    }

    const formik = useFormik({
        initialValues: userForEdit,
        validationSchema: editUserSchema,
        onSubmit: async (values, {setSubmitting, setErrors}) => {
            setSubmitting(true)
            try {
                await updateSubscriptionAuth(values.id, null, values.password).then(res => {
                    if (res?.status == 200) {
                        cancel(true)
                    }
                })
            } catch (e: any) {
                setErrors(e.response?.data?.errors)
            } finally {
                setSubmitting(true)
            }
        },
    })

    return (
        <>
            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                >
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>رمز عبور</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...formik.getFieldProps('password')}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched.password && formik.errors?.password},
                                {
                                    'is-valid': formik.touched.password && !formik.errors?.password,
                                }
                            )}
                            type='text'
                            name='password'
                            autoComplete='off'
                            disabled={formik.isSubmitting || isLoading}
                        />
                        {/* end::Input */}
                        {formik.touched.password && formik.errors.password && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.password}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Input group */}

                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                    <button
                        type='reset'
                        onClick={() => cancel()}
                        className='btn btn-light me-3'
                        data-kt-users-modal-action='cancel'
                        disabled={formik.isSubmitting || isLoading}
                    >
                        لغو
                    </button>

                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
                    >
                        <span className='indicator-label'>ذخیره</span>
                        {(formik.isSubmitting || isLoading) && (
                            <span className='indicator-progress'>
                لطفا منتظر بمانید...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}
            </form>
            {(formik.isSubmitting || isLoading) && <ListLoading/>}
        </>
    )
}

export {SubEditModalForm}
