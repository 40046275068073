import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useListView} from "../UserListViewProvider";
import {useQueryResponseUsers} from "../UsersQueryResponseProvider";
import {User} from "../../../../_models";
import {addPlanToUser} from "../../../../requests/_admin_requests";
import clsx from "clsx";
import {ListLoading} from "../../ListLoading";

type Props = {
    isLoading: boolean
    user: User,
    plans: any
}

const editUserSchema = Yup.object().shape({
    plan_id: Yup.string()
        .required('انتخاب پلن ضروری است.'),
    payment_type: Yup.string().required('انتخاب طرف پرداخت ضروری است.'),
})

const UserSubModalForm: FC<Props> = ({user, isLoading: isUserLoading, plans}) => {
    const {setSecondModal, setMessage} = useListView()
    const {refetch} = useQueryResponseUsers()

    const [planForSelect] = useState({
        plan_id: '',
        payment_type: ''
    })

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch()
        }
        if (setSecondModal) {
            setSecondModal(undefined)
        }
    }

    const formik = useFormik({
        initialValues: planForSelect,
        validationSchema: editUserSchema,
        onSubmit: async (values, {setSubmitting, setErrors}) => {
            setSubmitting(true)
            try {
                addPlanToUser(values.plan_id, user.id, values.payment_type)
                    .then(res => {
                        if (res.status == 200) {
                            if (setMessage) {
                                setMessage({
                                    type: 'success',
                                    text: res?.data?.data?.message
                                })
                                setTimeout(() => setMessage(undefined), 7000)
                            }
                            if (setSecondModal) {
                                setSecondModal(undefined)
                            }
                        }
                    })
            } catch (e: any) {
                setErrors(e.response?.data?.errors)
            } finally {
                setSubmitting(false)
            }
        },
    })

    return (
        <>
            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                >
                    <label className="form-label">اشتراک برای {user.name} {user.phone}</label>

                    <select aria-label="Select plan"
                            {...formik.getFieldProps('plan_id')}
                            className={clsx(
                                'form-select',
                                {'is-invalid': formik.touched.plan_id && formik.errors?.plan_id},
                                {
                                    'is-valid': formik.touched.plan_id && !formik.errors?.plan_id,
                                }
                            )}
                    >
                        <option>انتخاب اشتراک</option>
                        {plans && plans.map((plan, key) => <option key={key} value={plan?.id}>{plan.name}</option>)}
                    </select>

                    {formik.touched.plan_id && formik.errors?.plan_id && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors?.plan_id}</span>
                            </div>
                        </div>
                    )}
                </div>
                {/* end::Scroll */}

                <div className="mt-8">
                    <label className="form-label mb-5">طرف پرداخت:</label>
                    <div className="d-flex flex-column gap-4">
                        <div className="form-check me-3">
                            <input
                                {...formik.getFieldProps('payment_type')}
                                type="radio"
                                name="payment_type"
                                value="user"
                                id="paymentMethodUser"
                                className={clsx('form-check-input', {
                                    'is-invalid':
                                        formik.touched.payment_type && formik.errors?.payment_type,
                                })}
                            />
                            <label htmlFor="paymentMethodUser" className="form-check-label">
                                مشتری
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                {...formik.getFieldProps('payment_type')}
                                type="radio"
                                name="payment_type"
                                value="system"
                                id="paymentMethodSystem"
                                className={clsx('form-check-input', {
                                    'is-invalid':
                                        formik.touched.payment_type && formik.errors?.payment_type,
                                })}
                            />
                            <label htmlFor="paymentMethodSystem" className="form-check-label">
                                سیستم
                            </label>
                        </div>
                    </div>
                    {formik.touched.payment_type && formik.errors?.payment_type && (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                <span role="alert">{formik.errors?.payment_type}</span>
                            </div>
                        </div>
                    )}
                </div>

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                    <button
                        type='reset'
                        onClick={() => cancel()}
                        className='btn btn-light me-3'
                        data-kt-users-modal-action='cancel'
                        disabled={formik.isSubmitting || isUserLoading}
                    >
                        لغو
                    </button>

                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
                    >
                        <span className='indicator-label'>ذخیره</span>
                        {(formik.isSubmitting || isUserLoading) && (
                            <span className='indicator-progress'>
                لطفا منتظر بمانید...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}
            </form>
            {(formik.isSubmitting || isUserLoading) && <ListLoading/>}
        </>
    )
}

export {UserSubModalForm}
