import React, {FC, useEffect, useState} from 'react'
import {AdminDashboardData} from "../../_models";
import {getAdminDashboardData} from "../../requests/_admin_requests";

const AdminDashboard: FC = () => {

    const [data, setData] = useState<AdminDashboardData>()

    useEffect(() => {
        getAdminDashboardData().then(res => {
            if (res.status === 200) {
                setData(res.data?.data)
            }
        })
    }, []);

    useEffect(() => {
        document.title = 'داشبورد'
    }, []);

    return <>
        <div className='d-flex flex-wrap flex-md-nowrap justify-content-between gap-5 my-5'>
            <div className='col-12 col-md-4 card bg-gray-100 d-flex flex-column px-5 py-10 h-175px'>
                <div className='text-primary fs-3 fw-bold'>
                    کاربران امروز
                </div>
                <div className="fs-1 fw-bold text-gray-600 position-relative m-auto">
                    {data?.today_users}
                </div>
            </div>
            <div className='col-12 col-md-4 card bg-gray-100 d-flex flex-column px-5 py-10 h-175px'>
                <div className='text-primary fs-3 fw-bold'>
                    کاربران فعال
                </div>
                <div className="fs-1 fw-bold text-gray-600 position-relative m-auto">
                    {data?.active_users}
                </div>
            </div>
            <div className='col-md-4 col-12 card bg-gray-100 d-flex flex-column px-5 py-10 h-175px'>
                <div className='text-primary fs-3 fw-bold'>
                    کل کاربران
                </div>
                <div className="fs-1 fw-bold text-gray-600 position-relative m-auto">
                    {data?.all_users}
                </div>
            </div>
        </div>

        <div className='d-flex flex-wrap flex-md-nowrap justify-content-between gap-5 my-5'>
            <div className='col-12 col-md-4 card bg-gray-100 d-flex flex-column px-5 py-10 h-175px'>
                <div className='text-primary fs-3 fw-bold'>
                    فروش امروز
                </div>
                <div className="fs-1 fw-bold text-gray-600 position-relative m-auto">
                    {data?.today_payments}
                </div>
            </div>
            <div className='col-12 col-md-4 card bg-gray-100 d-flex flex-column px-5 py-10 h-175px'>
                <div className='text-primary fs-3 fw-bold'>
                    فروش ماه
                </div>
                <div className="fs-1 fw-bold text-gray-600 position-relative m-auto">
                    {data?.month_payments}
                </div>
            </div>
            <div className='col-md-4 col-12 card bg-gray-100 d-flex flex-column px-5 py-10 h-175px'>
                <div className='text-primary fs-3 fw-bold'>
                    فروش کل
                </div>
                <div className="fs-1 fw-bold text-gray-600 position-relative m-auto">
                    {data?.all_payments}
                </div>
            </div>
        </div>
    </>
}
export default AdminDashboard