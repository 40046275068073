import React from 'react';
import {Link} from 'react-router-dom';
import {useIntl} from 'react-intl';

const tr = {
    loginTitle: {
        id: "AUTH.LOGIN.TITLE"
    },
    haveAccount: {
        id: 'AUTH.GENERAL.HAVE_ACCOUNT'
    }
};
const AccountPrompt = () => {
    const {formatMessage: text} = useIntl();

    return (
        <div className='text-gray-500 fw-semibold fs-6'>
            {text(tr.haveAccount) + ' '}
            <Link to='/auth/login' className='link-primary'>
                {text(tr.loginTitle)}
            </Link>
        </div>
    );
};

export default AccountPrompt;
