import {FC, useState} from 'react'
import {useFormik} from 'formik'
import {useListView} from "../RadiusListViewProvider";
import {Radius} from "../../../../_models";
import {isNotEmpty} from "../../../../../_metronic/helpers";
import clsx from "clsx";
import {ListLoading} from "../../ListLoading";
import {useQueryResponseRadius} from "../RadiusQueryResponseProvider";
import {createRadius, updateRadius} from "../../../../requests/_admin_requests";

type Props = {
    isLoading: boolean
    data: Radius
}

const RadiusEditModalForm: FC<Props> = ({data, isLoading}) => {
    const {setItemIdForUpdate} = useListView()
    const {refetch} = useQueryResponseRadius()

    const [radiusForEdit] = useState<Radius>({
        ...data
    })

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch()
        }
        setItemIdForUpdate(undefined)
    }

    const formik = useFormik({
        initialValues: radiusForEdit,
        onSubmit: async (values, {setSubmitting, setErrors}) => {
            setSubmitting(true)
            try {
                if (isNotEmpty(values.id)) {
                    await updateRadius(values).then(res => {
                        if (res?.status == 200) {
                            cancel(true)
                        }
                    })
                } else {
                    await createRadius(values).then(res => {
                        if (res?.status == 201) {
                            cancel(true)
                        }
                    })
                }
            } catch (e: any) {
                setErrors(e.response?.data?.errors)
            } finally {
                setSubmitting(true)
            }
        },
    })

    return (
        <>
            <form id='kt_modal_add_radius_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_radius_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_radius_header'
                    data-kt-scroll-wrappers='#kt_modal_add_radius_scroll'
                    data-kt-scroll-offset='300px'
                >
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>نام نمایشی</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...formik.getFieldProps('name')}
                            type='text'
                            name='name'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched.name && formik.errors?.name},
                                {
                                    'is-valid': formik.touched.name && !formik.errors?.name,
                                }
                            )}
                            autoComplete='off'
                            disabled={formik.isSubmitting || isLoading}
                        />
                        {formik.touched.name && formik.errors?.name && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.name}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>هاست (IP)</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...formik.getFieldProps('host')}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched.host && formik.errors.host},
                                {
                                    'is-valid': formik.touched.host && !formik.errors.host,
                                }
                            )}
                            type='text'
                            name='host'
                            autoComplete='off'
                            disabled={formik.isSubmitting || isLoading}
                        />
                        {/* end::Input */}
                        {formik.touched.host && formik.errors.host && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.host}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>پورت</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            placeholder='3306'
                            {...formik.getFieldProps('port')}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched?.port && formik.errors?.port},
                                {
                                    'is-valid': formik.touched?.port && !formik.errors?.port,
                                }
                            )}
                            type='text'
                            name='port'
                            autoComplete='off'
                            disabled={formik.isSubmitting || isLoading}
                        />
                        {/* end::Input */}
                        {formik.touched?.port && formik.errors?.port && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.port}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>یوزرنیم</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            placeholder='root'
                            {...formik.getFieldProps('username')}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched?.username && formik.errors?.username},
                                {
                                    'is-valid': formik.touched?.username && !formik.errors?.username,
                                }
                            )}
                            type='text'
                            name='username'
                            autoComplete='off'
                            disabled={formik.isSubmitting || isLoading}
                        />
                        {/* end::Input */}
                        {formik.touched?.username && formik.errors?.username && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.username}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>پسورد</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...formik.getFieldProps('password')}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched?.password && formik.errors?.password},
                                {
                                    'is-valid': formik.touched?.password && !formik.errors?.password,
                                }
                            )}
                            type='text'
                            name='password'
                            autoComplete='off'
                            disabled={formik.isSubmitting || isLoading}
                        />
                        {/* end::Input */}
                        {formik.touched?.password && formik.errors?.password && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.password}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Input group */}


                    {/* begin::Input */}
                    <div className="form-check form-check-custom form-check-solid">
                        <input
                            {...formik.getFieldProps('is_active')}
                            className={clsx(
                                'form-check-input',
                                {'is-invalid': formik.touched?.is_active && formik.errors?.is_active},
                                {
                                    'is-valid': formik.touched?.is_active && !formik.errors?.is_active,
                                }
                            )}
                            checked={formik.values.is_active}
                            disabled={formik.isSubmitting || isLoading}
                            type="checkbox"
                            id="is_active"/>
                        <label className="form-check-label" htmlFor="is_active">
                            فعال
                        </label>
                    </div>
                    {/* end::Input */}
                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                    <button
                        type='reset'
                        onClick={() => cancel()}
                        className='btn btn-light me-3'
                        data-kt-radiuss-modal-action='cancel'
                        disabled={formik.isSubmitting || isLoading}
                    >
                        لغو
                    </button>

                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-radiuss-modal-action='submit'
                        disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
                    >
                        <span className='indicator-label'>ذخیره</span>
                        {(formik.isSubmitting || isLoading) && (
                            <span className='indicator-progress'>
                لطفا منتظر بمانید...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}
            </form>
            {(formik.isSubmitting || isLoading) && <ListLoading/>}
        </>
    )
}

export {RadiusEditModalForm}
