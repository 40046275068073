import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Subscription} from "../../../../_models";
import {isNotEmpty} from "../../../../../_metronic/helpers";
import {createUser, updateSub, updateUser} from "../../../../requests/_admin_requests";
import clsx from "clsx";
import {ListLoading} from "../../ListLoading";
import {useListView} from "../AdminSubListViewProvider";
import {useQueryResponseSubs} from "../AdminSubQueryResponseProvider";

type Props = {
    isLoading: boolean
    sub: Subscription
}

const editSubSchema = Yup.object().shape({
    username: Yup.string()
        .max(50, 'حداکثر ۵۰ کاراکتر')
        .required('وارد کردن نام ضروری است.'),
    password: Yup.string()
        .max(50, 'حداکثر ۵۰ کاراکتر')
        .required('وارد کردن نام ضروری است.'),
    days: Yup.string()
        .required('وارد کردن تعداد روزها ضروری است.'),
})

const AdminSubEditModalForm: FC<Props> = ({sub, isLoading}) => {
    const {setItemIdForUpdate} = useListView()
    const {refetch} = useQueryResponseSubs()

    const [userForEdit] = useState<Subscription>({
        ...sub
    })

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch()
        }
        setItemIdForUpdate(undefined)
    }

    const formik = useFormik({
        initialValues: userForEdit,
        validationSchema: editSubSchema,
        onSubmit: async (values, {setSubmitting, setErrors}) => {
            setSubmitting(true)
            try {
                if (isNotEmpty(values.id)) {
                    await updateSub(values).then(res => {
                        if (res?.status == 200) {
                            cancel(true)
                        }
                    })
                }
            } catch (e: any) {
                setErrors(e.response?.data?.errors)
            } finally {
                setSubmitting(false)
            }
        },
    })

    return (
        <>
            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                >
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>نام کاربری اتصال</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...formik.getFieldProps('username')}
                            type='text'
                            name='username'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched?.username && formik.errors?.username},
                                {
                                    'is-valid': formik.touched?.username && !formik.errors?.username,
                                }
                            )}
                            autoComplete='off'
                            disabled={formik.isSubmitting || isLoading}
                        />
                        {formik.touched?.username && formik.errors?.username && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.username}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>رمز عبور اتصال</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...formik.getFieldProps('password')}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched?.password && formik.errors?.password},
                                {
                                    'is-valid': formik.touched?.password && !formik.errors?.password,
                                }
                            )}
                            type='text'
                            name='password'
                            autoComplete='off'
                            disabled={formik.isSubmitting || isLoading}
                        />
                        {/* end::Input */}
                        {formik.touched.password && formik.errors?.password && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.password}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>تعداد روزهای اشتراک</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            {...formik.getFieldProps('days')}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched?.days && formik.errors?.days},
                                {
                                    'is-valid': formik.touched?.days && !formik.errors?.days,
                                }
                            )}
                            type='text'
                            name='days'
                            autoComplete='off'
                            disabled={formik.isSubmitting || isLoading}
                        />
                        {/* end::Input */}
                        {formik.touched?.days && formik.errors?.days && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.days}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Input group */}

                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                    <button
                        type='reset'
                        onClick={() => cancel()}
                        className='btn btn-light me-3'
                        data-kt-users-modal-action='cancel'
                        disabled={formik.isSubmitting || isLoading}
                    >
                        لغو
                    </button>

                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
                    >
                        <span className='indicator-label'>ذخیره</span>
                        {(formik.isSubmitting || isLoading) && (
                            <span className='indicator-progress'>
                لطفا منتظر بمانید...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}
            </form>
            {(formik.isSubmitting || isLoading) && <ListLoading/>}
        </>
    )
}

export {AdminSubEditModalForm}
