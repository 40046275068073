
/*
* format number
* ex: 1000000 to 1,000,000
*/
export const formatNumber = (number: string|number) => {
    if(typeof number == "number"){
        number = number.toString()
    }

    if(!number) return 0;

    return number.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
