import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useListView} from "../UserListViewProvider";
import {useQueryResponseUsers} from "../UsersQueryResponseProvider";
import {User} from "../../../../_models";
import {isNotEmpty} from "../../../../../_metronic/helpers";
import {createUser, updateUser} from "../../../../requests/_admin_requests";
import clsx from "clsx";
import {ListLoading} from "../../ListLoading";

type Props = {
    isUserLoading: boolean
    user: User
}

const editUserSchema = Yup.object().shape({
    email: Yup.string()
        .email('ایمیل اشتباه است.'),
    name: Yup.string()
        .max(50, 'حداکثر ۵۰ کاراکتر')
        .required('وارد کردن نام ضروری است.'),
    phone: Yup.string()
        .min(10, 'شماره موبایل باید حداقل ۱۰ کاراکتر باشد.')
        .required('وارد کردن شماره موبایل ضروری است.'),
})

const UserEditModalForm: FC<Props> = ({user, isUserLoading}) => {
    const {setItemIdForUpdate} = useListView()
    const {refetch} = useQueryResponseUsers()

    const [userForEdit] = useState<User>({
        ...user
    })

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            refetch()
        }
        setItemIdForUpdate(undefined)
    }

    const formik = useFormik({
        initialValues: userForEdit,
        validationSchema: editUserSchema,
        onSubmit: async (values, {setSubmitting, setErrors}) => {
            setSubmitting(true)
            try {
                if (isNotEmpty(values.id)) {
                    await updateUser(values).then(res => {
                        if (res?.status == 200) {
                            cancel(true)
                        }
                    })
                } else {
                    await createUser(values).then(res => {
                        if (res?.status == 201) {
                            cancel(true)
                        }
                    })
                }
            } catch (e: any) {
                setErrors(e.response?.data?.errors)
            } finally {
                setSubmitting(false)
            }
        },
    })

    return (
        <>
            <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
                {/* begin::Scroll */}
                <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                >
                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>نام</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            placeholder='نام و نام خانوادگی'
                            {...formik.getFieldProps('name')}
                            type='text'
                            name='name'
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched.name && formik.errors?.name},
                                {
                                    'is-valid': formik.touched.name && !formik.errors?.name,
                                }
                            )}
                            autoComplete='off'
                            disabled={formik.isSubmitting || isUserLoading}
                        />
                        {formik.touched.name && formik.errors?.name && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.name}</span>
                                </div>
                            </div>
                        )}
                        {/* end::Input */}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>ایمیل</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            placeholder='ایمیل'
                            {...formik.getFieldProps('email')}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched.email && formik.errors.email},
                                {
                                    'is-valid': formik.touched.email && !formik.errors.email,
                                }
                            )}
                            type='email'
                            name='email'
                            autoComplete='off'
                            disabled={formik.isSubmitting || isUserLoading}
                        />
                        {/* end::Input */}
                        {formik.touched.email && formik.errors.email && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.email}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Input group */}

                    {/* begin::Input group */}
                    <div className='fv-row mb-7'>
                        {/* begin::Label */}
                        <label className='required fw-bold fs-6 mb-2'>شماره موبایل</label>
                        {/* end::Label */}

                        {/* begin::Input */}
                        <input
                            placeholder='09** *** ****'
                            {...formik.getFieldProps('phone')}
                            className={clsx(
                                'form-control form-control-solid mb-3 mb-lg-0',
                                {'is-invalid': formik.touched?.phone && formik.errors?.phone},
                                {
                                    'is-valid': formik.touched?.phone && !formik.errors?.phone,
                                }
                            )}
                            type='text'
                            name='phone'
                            autoComplete='off'
                            disabled={formik.isSubmitting || isUserLoading}
                        />
                        {/* end::Input */}
                        {formik.touched?.phone && formik.errors?.phone && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors?.phone}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Input group */}

                </div>
                {/* end::Scroll */}

                {/* begin::Actions */}
                <div className='text-center pt-15'>
                    <button
                        type='reset'
                        onClick={() => cancel()}
                        className='btn btn-light me-3'
                        data-kt-users-modal-action='cancel'
                        disabled={formik.isSubmitting || isUserLoading}
                    >
                        لغو
                    </button>

                    <button
                        type='submit'
                        className='btn btn-primary'
                        data-kt-users-modal-action='submit'
                        disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
                    >
                        <span className='indicator-label'>ذخیره</span>
                        {(formik.isSubmitting || isUserLoading) && (
                            <span className='indicator-progress'>
                لطفا منتظر بمانید...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
                        )}
                    </button>
                </div>
                {/* end::Actions */}
            </form>
            {(formik.isSubmitting || isUserLoading) && <ListLoading/>}
        </>
    )
}

export {UserEditModalForm}
