import React, {useEffect} from 'react'
import {KTIcon} from "../../../../_metronic/helpers";
import {cleanRadius} from "../../../requests/_admin_requests";
import {useListView} from "./RadiusListViewProvider";

const RadiusCleanModal = () => {
    const {setThirdModal, setMessage, thirdModal} = useListView()

    useEffect(() => {
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    const cancel = () => {
        if (setThirdModal) {
            setThirdModal(undefined)
        }
    }

    const call = () => {
        cleanRadius(thirdModal).then(res => {
            if (setMessage) {
                setMessage({
                    type: 'danger',
                    text: res?.data?.data?.message
                })
                setTimeout(() => setMessage(undefined), 7000)
            }
            cancel()
        })
    }

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-650px'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <div
                            className="alert alert-dismissible bg-light-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 m-0">
                            <button type="button"
                                    className="position-absolute top-0 end-0 m-2 btn btn-icon btn-icon-danger"
                                    onClick={cancel}
                                    data-bs-dismiss="alert">
                                <KTIcon iconName='cross' className='fs-1'/>
                            </button>

                            <span className="svg-icon svg-icon-5tx svg-icon-danger mb-5">آیا مطمئنید؟</span>

                            <div className="text-center">
                                <h5 className="fw-bolder fs-1 mb-5">پاکسازی ردیوس</h5>

                                <div className="separator separator-dashed border-danger opacity-25 mb-5"></div>

                                <div className="mb-9">
                                    تمام سطرهای جدول radcheck ردیوس پاک سازی خواهد شد.
                                </div>
                                <div className="d-flex flex-center flex-wrap">
                                    <a onClick={cancel}
                                       className="btn btn-outline btn-outline-danger btn-active-danger m-2">لغو</a>
                                    <a onClick={call} className="btn btn-danger m-2">بله، پاک سازی شود.</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export {RadiusCleanModal}
