import {FC} from 'react'

type Props = {
  type?: string
  text: string
}

const BadgeCell: FC<Props> = ({type= 'info', text}) => (
    <>
        <div className={`badge badge-light-${type} fw-bolder p-3`}>
            {text}
        </div>
    </>
)

export {BadgeCell}
