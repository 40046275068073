/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {ID, KTIcon, QUERIES} from "../../../../_metronic/helpers";
import {useListView} from "./CouponListViewProvider";
import {useQueryClient} from "react-query";
import {useQueryResponseCoupons} from "./CouponsQueryResponseProvider";
import {MenuComponent} from "../../../../_metronic/assets/ts/components";
import {deleteCoupon} from "../../../requests/_admin_requests";


type Props = {
    id: ID
}

const CouponActionCell: FC<Props> = ({id}) => {
    const {setItemIdForUpdate} = useListView()
    const queryClient = useQueryClient()
    const {query} = useQueryResponseCoupons()

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const openEditModal = () => {
        setItemIdForUpdate(id)
    }

    // const deleteItem = () => {
    //     deleteCoupon(id).then(() => {
    //         queryClient.invalidateQueries([`${QUERIES.COUPON_LIST}-${query}`])
    //     })
    // }

    return (
        <>
            <a
                href='#'
                className='btn btn-light btn-active-light-primary btn-sm'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                عملیات
                <KTIcon iconName='down' className='fs-5 m-0'/>
            </a>
            {/* begin::Menu */}
            <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                data-kt-menu='true'
            >
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                    <a className='menu-link px-3' onClick={openEditModal}>
                        ویرایش
                    </a>
                </div>
                {/* end::Menu item */}

                {/*begin::Menu item*/}
                {/*<div className='menu-item px-3'>*/}
                {/*    <a*/}
                {/*        className='menu-link px-3'*/}
                {/*        data-kt-plans-table-filter='delete_row'*/}
                {/*        onClick={async () => deleteItem()}*/}
                {/*    >*/}
                {/*        حذف*/}
                {/*    </a>*/}
                {/*</div>*/}
                {/*end::Menu item*/}

            </div>
            {/* end::Menu */}
        </>
    )
}

export {CouponActionCell}
