// CharacterInputBox.tsx
import React, {ChangeEvent, KeyboardEvent, ClipboardEvent, useState} from 'react';

interface CharacterInputBoxProps {
    length?: number;
    onComplete: (value: string) => void;
    getFieldProps?: any
}

const SingleCharInputBox: React.FC<CharacterInputBoxProps> = ({length = 5, onComplete, getFieldProps}) => {
    const [values, setValues] = useState<string[]>(Array(length).fill(''));

    const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const {value} = e.target;
        if (!/^\d*$/.test(value)) {
            return;
        }

        const newValues = [...values];
        newValues[index] = value;
        setValues(newValues);

        if (value && index < length - 1) {
            const nextInput = document.getElementById(`char-input-${index + 1}`) as HTMLInputElement;
            nextInput?.focus();
        }

        if (newValues.every(char => char !== '')) {
            onComplete(newValues.join(''));
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && !values[index] && index > 0) {
            const prevInput = document.getElementById(`char-input-${index - 1}`) as HTMLInputElement;
            prevInput?.focus();
        }
    };

    const handlePaste = (e: ClipboardEvent<HTMLInputElement>, index: number) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData('Text');
        if (!/^\d*$/.test(pasteData)) {
            return;
        }

        const pasteArray = pasteData.split('').slice(0, length - index);
        const newValues = [...values];

        for (let i = 0; i < pasteArray.length; i++) {
            newValues[index + i] = pasteArray[i];
        }

        setValues(newValues);

        if (newValues.every(char => char !== '')) {
            onComplete(newValues.join(''));
        } else {
            const nextInputIndex = index + pasteArray.length;
            if (nextInputIndex < length) {
                const nextInput = document.getElementById(`char-input-${nextInputIndex}`) as HTMLInputElement;
                nextInput?.focus();
            }
        }
    };

    return (
        <div className="d-flex gap-5 justify-content-center" dir='ltr'>
            {values.map((value, index) => (
                <div key={index}>
                    <input
                        type="text"
                        id={`char-input-${index}`}
                        maxLength={1}
                        className="form-control w-50px h-50px text-center"
                        value={value}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onPaste={(e) => handlePaste(e, index)}
                        autoComplete={'off'}
                    />
                </div>
            ))}
        </div>
    );
};

export default SingleCharInputBox;
