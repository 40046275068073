import React, {FC, useEffect, useRef, useState} from 'react'
import {SearchComponent} from "../../../../../_metronic/assets/ts/components";
import {initialQueryState, KTIcon} from "../../../../../_metronic/helpers";
import {useQueryRequest} from "../../core/QueryRequestProvider";
import clsx from "clsx";
import {useSearchParams} from "react-router-dom";

const Search: FC = () => {
    const element = useRef<HTMLDivElement | null>(null)
    const wrapperElement = useRef<HTMLDivElement | null>(null)
    const resultsElement = useRef<HTMLDivElement | null>(null)
    const suggestionsElement = useRef<HTMLDivElement | null>(null)
    const emptyElement = useRef<HTMLDivElement | null>(null)
    const {updateState} = useQueryRequest()
    const [searchValue, setSearchValue] = useState<string>()
    const [displayDropdown, setDisplayDropdown] = useState(false)
    const [filter, setFilter] = useState<'plan_name' | 'username' | 'user_id'>('username')
    const [searchParams] = useSearchParams();
    const user_id = searchParams.get('user_id');

    const processs = (search: SearchComponent) => {
        setTimeout(function () {
            // Complete search
            search.complete()
        }, 1500)
    }

    const clear = (search: SearchComponent) => {
        // Show recently viewed
        suggestionsElement.current!.classList.remove('d-none')
        // Hide results
        resultsElement.current!.classList.add('d-none')
        // Hide empty message
        emptyElement.current!.classList.add('d-none')
    }

    useEffect(() => {
        // Initialize search handler
        const searchObject = SearchComponent.createInsance('#kt_header_search')

        // Search handler
        searchObject!.on('kt.search.process', processs)

        // Clear handler
        searchObject!.on('kt.search.clear', clear)


    }, [])

    useEffect(()=>{
        if (user_id) {
            setFilter('user_id')
            setSearchValue(user_id)
            updateState({filter: {[filter]: searchValue}, ...initialQueryState})
        }
    }, [user_id, searchValue])

    const doSearch = () => {
        if (searchValue) {
            updateState({filter: {[filter]: searchValue}, ...initialQueryState})
            setDisplayDropdown(false)
        }
    }

    return (
        <>
            <div
                id='kt_header_search'
                className='d-flex align-items-stretch'
                data-kt-search-keypress='true'
                data-kt-search-min-length='2'
                data-kt-search-enter='enter'
                data-kt-search-layout='menu'
                data-kt-menu-trigger='auto'
                data-kt-menu-overflow='false'
                data-kt-menu-permanent='true'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-close='bottom-end'
                ref={element}
            >
                <div
                    className='d-flex align-items-center'
                    data-kt-search-element='toggle'
                    id='kt_header_search_toggle'
                >
                    <div className='btn btn-secondary btn-active-light-primary'
                         onClick={() => setDisplayDropdown(true)}
                    >
                        جستجوی پیشرفته&nbsp;
                        <KTIcon iconName='magnifier' className='fs-1'/>
                    </div>
                </div>

                <div
                    data-kt-search-element='content'
                    className={clsx(
                        'menu menu-sub menu-sub-dropdown p-7 w-325px w-md-375px',
                        {'d-none': !displayDropdown},
                    )}
                >
                    <div
                        ref={wrapperElement}
                        data-kt-search-element='wrapper'
                    >
                        <form
                            dir='rtl'
                            data-kt-search-element='form'
                            className='w-100 position-relative mb-3'
                            autoComplete='off'
                            onSubmit={e => {
                                e.preventDefault()
                                doSearch()
                            }}
                        >
                            <KTIcon
                                iconName='magnifier'
                                className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 translate-middle-y ms-0'
                            />

                            <input
                                dir='rtl'
                                type='text'
                                className='form-control form-control-flush ps-10'
                                name='search'
                                placeholder='جستجو...'
                                data-kt-search-element='input'
                                onChange={e => setSearchValue(e.target.value)}
                            />

                        </form>

                    </div>


                    <div className='mb-5'>
                        <div className='nav-group nav-group-fluid'>
                            <label>
                                <input type='radio' className='btn-check' name='type' value='username' defaultChecked
                                       onClick={() => setFilter('username')}/>
                                <span
                                    className='btn btn-sm btn-color-muted btn-active btn-active-primary'>نام کاربری</span>
                            </label>

                            <label>
                                <input type='radio' className='btn-check' name='type' value='plan_name'
                                       onClick={() => setFilter('plan_name')}/>
                                <span
                                    className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>نام اشتراک</span>
                            </label>
                        </div>
                    </div>

                    <div className='d-flex justify-content-end'>

                        <a
                            onClick={() => doSearch()}
                            className='btn btn-sm fw-bolder btn-primary'
                            data-kt-search-element='advanced-options-form-search'
                        >
                            جستجو
                        </a>
                    </div>

                </div>
            </div>
        </>
    )
}

export {Search}
