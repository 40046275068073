import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {addPlanToInvoice, checkCoupon, getSubscriptions} from '../../../requests/_requests'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../auth/core/Auth'
import {SubscriptionArray} from "../../auth/core/_models";
import clsx from "clsx";
import * as Yup from "yup";
import AuthSubtitle from "../../auth/components/partials/AuthSubtitle";
import ErrorAlert from "../../auth/components/partials/ErrorAlert";
import {AlertMessage} from "./AlertMessage";

const tr = {
    title: {
        id: "SUBS_PAGE.TITLE"
    },
    subtitle: {
        id: "AUTH.SUBTITLE"
    },
    select: {
        id: 'SUBS_PAGE.SELECT'
    },
    selected: {
        id: 'SUBS_PAGE.SELECTED'
    },
    desc: {
        id: 'SUBS_PAGE.DESC.TITLE'
    },
    descList: {
        id: 'SUBS_PAGE.DESC.LIST.'
    },
    next: {
        id: 'NEXT'
    },
    previous: {
        id: 'PREVIOUS'
    },
    monthlyPrice: {
        id: 'SUBS_PAGE.ITEM_MONTH'
    },
    fullPrice: {
        id: 'SUBS_PAGE.ITEM_SUM'
    },
    couponAsk: {
        id: 'COUPON.HAVE?'
    },
    couponCheck: {
        id: 'COUPON.CHECK'
    },
    validateSub: {
        id: 'VALIDATION.SELECT_SUBSCRIPTION'
    }
};

// todo: replace the SubSelect parts with SubSelect component

export function Subscription() {
    const [loadingCoupon, setLoadingCoupon] = useState(false)
    const {formatMessage: text} = useIntl()
    const navigate = useNavigate();
    const [subs, setSubs] = useState<SubscriptionArray>()
    const [active, setActive] = useState<number>()
    const [descItems] = useState([0, 1, 2, 3, 4])
    const [selectedSubId, setSelectedSubId] = useState<number>(0)
    const [coupon, setCoupon] = useState("")
    const [couponError, setCouponError] = useState("")
    const [couponSuccess, setCouponSuccess] = useState("")

    const handleSelect = (i: React.SetStateAction<number | undefined>, subId: React.SetStateAction<number>) => {
        setActive(i)
        setSelectedSubId(subId)
        formik.setFieldValue('subId', subId)
    }

    const handleCheckCoupon = () => {
        setLoadingCoupon(true)
        checkCoupon(selectedSubId, coupon)
            .then(res => {
                setCouponSuccess(res.data.message)
            })
            .catch(e => {
                setCouponError(e.response.data.message)
            })
            .finally(() => {
                setLoadingCoupon(false)
            })
    }

    const handleSetCoupon = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setCouponError('')
        setCouponSuccess('')
        setCoupon(e.target.value)
    }

    const subValidations = Yup.object().shape({
        subId: Yup.number().required(text(tr.validateSub)).moreThan(0, text(tr.validateSub))
    })

    const formik = useFormik({
        initialValues: {
            subId: selectedSubId,
            coupon: coupon
        },
        validationSchema: subValidations,
        onSubmit: async (values, {setFieldError, setSubmitting, setErrors, validateField}) => {
            validateField('subId')
            try {
                const {data} = await addPlanToInvoice(values.subId, values.coupon)
                navigate('/registration/account')
            } catch (error: any) {
                setErrors(error.response.data.errors)
            } finally {
                setSubmitting(false)
            }
        },
    })

    useEffect(() => {
        getSubscriptions().then((res) => {
            setSubs(res.data)
        })
    }, [])


    return (
        <form
            dir='rtl'
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
        >
            {/* begin::Heading */}
            <h1 className='text-dark fw-bolder'>
                {text(tr.title)}
            </h1>
            <AuthSubtitle/>
            {/* end::Heading */}

            {/* begin::Form group */}
            <div className='card card-custom card-flush my-5'>
                <div className="card-body">
                    <h5 className="text-primary">
                        {text(tr.desc)}
                    </h5>
                    <ul className="my-5 fs0">
                        {descItems.map(i => {
                            return (<li key={i}> {text({id: tr.descList.id + i})} </li>)
                        })}
                    </ul>
                    <div className="d-flex flex-column gap-5">
                        {subs && subs.map((sub, i) => (
                            <div key={i}
                                 onClick={() => handleSelect(i, sub.id)}
                                 className={`option card d-flex justify-content-between flex-column flex-md-row align-items-center p-3 my-2 ${active === i ? 'selected' : ''}`}
                            >
                                <div className="text-primary fw-bold">{sub.name}</div>
                                <div className="text-primary fw-bold">
                                    {text(tr.monthlyPrice, {price: sub.price_per_month})}
                                    <div className="fs-7 text-info m-0">
                                        {text(tr.fullPrice, {price: sub.price})}
                                    </div>
                                </div>
                                {
                                    i === active ?
                                        <button type="button" className="btn btn-sm btn-primary w-100 w-md-auto mt-2 mt-md-0">
                                            {text(tr.selected)}
                                            &nbsp;&nbsp;
                                            <i className="bi bi-check-circle-fill p-0"></i>
                                        </button>
                                        :
                                        <button type="button" className="btn btn-sm btn-secondary w-100 w-md-auto mt-2 mt-md-0">
                                            {text(tr.select)}
                                        </button>
                                }
                            </div>
                        ))}
                        <ErrorAlert touched={formik.touched?.subId} message={formik.errors?.subId}/>
                    </div>
                    <div className="discount-code-form mb-0">
                        <input type="text"
                               value={coupon}
                               onChange={handleSetCoupon}
                               className={clsx(
                                   'form-control',
                                   {'is-invalid': coupon && couponError}
                               )}
                               placeholder={text(tr.couponAsk)}/>
                        <button
                            disabled={loadingCoupon || !coupon}
                            onClick={handleCheckCoupon}
                            className="btn btn-dark">
                            {text(tr.couponCheck)}</button>
                    </div>
                    {coupon && (
                        <>
                            {AlertMessage(couponError, 'danger')}
                            {AlertMessage(couponSuccess, 'success')}
                        </>
                    )}
                </div>
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className='d-flex justify-content-end mb-10'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!formik.isSubmitting &&
                        <span className='indicator-label'>
                            {text(tr.next)}
                        </span>}
                    {formik.isSubmitting && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                            {text(tr.next)}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            {/* end::Action */}

        </form>
    )
}
