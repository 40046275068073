import React, {useEffect, useMemo} from 'react'
import {Row, useTable} from 'react-table'
import {CustomHeaderColumn} from '../columns/CustomHeaderColumn'
import {CustomRow} from '../columns/CustomRow'
import {Setting} from '../../../_models'
import {ListLoading} from '../ListLoading'
import {KTCardBody} from "../../../../_metronic/helpers";

import {QueryRequestProvider} from "../core/QueryRequestProvider";
import {
    SettingQueryResponseProvider,
    useQueryResponseDataSettings,
    useQueryResponseLoading
} from "./SettingQueryResponseProvider";
import {SettingColumns} from "./_settings_table_columns";
import {SettingListViewProvider, useListView} from "./SettingListViewProvider";
import {SettingEditModal} from "./setting-edit-modal/SettingEditModal";

const SettingTableInner = () => {
    const resData = useQueryResponseDataSettings()
    const isLoading = useQueryResponseLoading()
    const data = useMemo(() => resData, [resData])
    const columns = useMemo(() => SettingColumns, [])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })
    const {itemIdForUpdate} = useListView()

    return (
        <>
            <KTCardBody className='py-4'>
                <div className='table-responsive'>
                    <table
                        id='invoice-table'
                        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                        {...getTableProps()}
                    >
                        <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            {headers.map((column) => (
                                <CustomHeaderColumn key={column.id} column={column}/>
                            ))}
                        </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                        {rows.length > 0 ? (
                            rows.map((row: Row<Setting>, i) => {
                                prepareRow(row)
                                return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
                            })
                        ) : (
                            <tr>
                                <td colSpan={7}>
                                    <div
                                        className='d-flex text-center w-100 align-content-center justify-content-center'>
                                        در جدول تنظیمات داده ای ثبت نشده است.
                                    </div>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
                {isLoading && <ListLoading/>}
            </KTCardBody>
            {itemIdForUpdate !== undefined && <SettingEditModal />}
        </>
    )
}

const SettingTable = () => {
    return <QueryRequestProvider>
        <SettingQueryResponseProvider>
            <SettingListViewProvider>
                <SettingTableInner/>
            </SettingListViewProvider>
        </SettingQueryResponseProvider>
    </QueryRequestProvider>
}
export {SettingTable}
