import clsx from "clsx";

export const Alert = ({message}) => {

    const {
        type, text
    } = message

    return <>
        <div className={clsx("alert alert-dismissible border-dashed d-flex flex-column flex-sm-row p-5 my-10", {
            "bg-light-success" : type === 'success',
            "border border-success": type === 'success',
            "bg-light-danger" : type === 'danger',
            "border border-danger": type === 'danger'

        })}>
            <span className="svg-icon svg-icon-2hx svg-icon-success me-4 mb-5 mb-sm-0">
                <i className="fa-solid fa-check"></i>
            </span>
            <div className={clsx("d-flex flex-column pe-0 pe-sm-10", {
                "text-success": type === 'success',
                "text-danger": type === 'danger',
            })}>
                {type === 'success' && <h5 className="mb-1">درخواست شما با موفقیت انجام شد.</h5>}
                <span>{text}</span>
            </div>

            <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                <span className="svg-icon svg-icon-1 svg-icon-success">
                    <i className="fa-solid fa-xmark"></i>
                </span>
            </button>
        </div>
    </>
}