import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {DisableSidebar} from '../../_metronic/layout/core'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {WithChildren} from '../../_metronic/helpers'
import {RegistrationPage} from "../modules/registeration/RegistrationPage";
import {InstructionPage} from "../pages/instruction/InstructionPage";
import {InvoiceListPage} from "../pages/invoice/InvoiceListPage";
import {SupportPage} from "../pages/support/SupportPage";
import {SubscriptionPage} from "../pages/subscription/SubscriptionPage";

const PrivateRoutes = () => {
    const ProfilePage = lazy(() => import('../pages/profile/ProfilePage'))

    return (
        <Routes>
            <Route
                path='registration/*'
                element={
                    <RegistrationPage/>
                }
            />
            <Route element={<MasterLayout/>}>
                {/* Redirect to Dashboard after success login/registration */}
                <Route path='auth/*' element={<Navigate to='/dashboard'/>}/>
                {/* Pages */}
                <Route path='dashboard' element={<DashboardWrapper/>}/>
                <Route path='subscriptions' element={<SubscriptionPage/>}/>
                <Route path='instructions' element={<InstructionPage/>}/>
                <Route path='transactions' element={<InvoiceListPage/>}/>
                <Route path='support' element={<SupportPage/>}/>
                {/* Lazy Modules */}

                <Route
                    path='profile/*'
                    element={
                        <SuspensedView>
                            <ProfilePage/>
                        </SuspensedView>
                    }
                />

                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404'/>}/>
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return (
        <Suspense fallback={<TopBarProgress/>}>
            <DisableSidebar>{children}</DisableSidebar>
        </Suspense>
    )
}

export {PrivateRoutes}
